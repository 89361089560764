import * as React from "react";
import {
    Box, Typography
} from "@mui/material";

const Notifications = () => {
    return (<Box>
        <Typography variant="h4">Notifications</Typography>
    </Box>);
}

export { Notifications };