import React from "react";
import {
  Box,
  Select,
  Group,
  Stack,
  Text,
  Textarea,
  Flex,
  Button,
} from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";

const AiEvaluationEditor = () => {
  return (
    <Box>
      <Textarea minRows={5} label="Prompt" />
    </Box>
  );
};

type Action = {};

interface ActionEditorProps {
  value?: Action[];
  defaultValue?: Action[];
  onChange?: (val: Action[]) => void;
}

const ActionsEditor = ({
  value,
  defaultValue,
  onChange,
}: ActionEditorProps) => {
  const [_value, handleChange] = useUncontrolled({
    value,
    defaultValue,
    finalValue: [],
    onChange,
  });

  const addAction = () => {};

  const removeAction = (idx: number) => {};

  return (
    <Flex align="stretch" m="sm" mt="lg">
      <Box
        w="20px"
        sx={(theme) => ({
          borderLeft: `1px solid ${theme.colors.gray[4]}`,
          borderTop: `1px solid ${theme.colors.gray[4]}`,
          borderBottom: `1px solid ${theme.colors.gray[4]}`,
          borderRadius: "10px 0px 0px 10px",
          marginRight: "2px",
        })}
      />
      <Stack>
        <Group spacing={6} mt={"-13px"} ml="8px">
          <Text>Actions:</Text>
        </Group>
        <Box sx={{ marginBottom: 10 }} maw="1000px">
          <Button size="xs" onClick={addAction}>
            Add Action
          </Button>
        </Box>
      </Stack>
    </Flex>
  );
};

export const ActionEditor = () => {
  type ActionEvaluationType = "aiEvaluation" | "actionList";
  const [actionEvaluationType, setActionEvaluationType] =
    React.useState<ActionEvaluationType>("actionList");
  return (
    <Stack>
      <Group spacing={6}>
        <Text>Then </Text>
        <Select
          withinPortal
          value={actionEvaluationType}
          data={[
            { value: "aiEvaluation", label: "Run AI evaluation" },
            { value: "actionList", label: "Take actions" },
          ]}
          size="xs"
          w="190px"
          onChange={(val) => {
            setActionEvaluationType(val as ActionEvaluationType);
          }}
        />
        <Text>:</Text>
      </Group>
      {actionEvaluationType === "aiEvaluation" ? <AiEvaluationEditor /> : null}
      {actionEvaluationType === "actionList" ? <ActionsEditor /> : null}
    </Stack>
  );
};
