import { useMantineTheme, Avatar } from "@mantine/core";
import { Person } from "@mui/icons-material";
import { UserModel } from "../__generated__/operations";

function stringToColor(string: string, colors: string[]) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = Math.abs(string.charCodeAt(i) + ((hash << 5) - hash));
  };
  /* eslint-enable no-bitwise */

  return colors[hash % colors.length];
}

function stringAvatar(name: string, colors: string[]) {
  return {
    sx: {
      fontSize: "16px",
      pt: "3px",
    },
    color: stringToColor(name, colors),
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

interface AuralizeAvatarProps {
  user?: UserModel;
}

function AuralizeAvatar(props: AuralizeAvatarProps) {
  const theme = useMantineTheme();
  const colorsMap = [
    theme.colors.teal[4],
  ];
  if (props.user) {
    return (
      <Avatar
        radius={"xl"}
        size={45}
        {...stringAvatar(
          `${props.user.givenName} ${props.user.familyName}`,
          colorsMap
        )}
      />
    );
  } else {
    return (
      <Avatar>
        <Person />
      </Avatar>
    );
  }
}

export { AuralizeAvatar };
