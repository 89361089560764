import * as React from "react";
import {
  Box,
  Card,
  Blockquote,
  Grid,
  ActionIcon,
  useMantineTheme,
  Text,
  Transition,
  Flex,
  Group,
} from "@mantine/core";
import { IconX } from "@tabler/icons";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { useEditor, BubbleMenu } from "@tiptap/react";
import { IconSend } from "@tabler/icons";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";

import {
  useCreateCommentMutation,
  ConversationByIdQuery,
  UserModel,
} from "../../__generated__/operations";
import { Comment, CommentGroup } from "./types";
import { useCurrentUser } from "../../pages/auth/auth";
import { AuralizeAvatar } from "../../atoms/auralizeAvatar";
import { SanitizeHTML } from "../../atoms/sanitizeHtml";

interface CommentWidgetProps {
  visible: boolean;
  highlightedText: string;
  conversationId: string;
  transcriptPieceId: string;
  comments: CommentGroup | null;
  onClose: () => void;
}

function CommentWidget(props: CommentWidgetProps) {
  const [createCommentMut, { data, loading, error }] =
    useCreateCommentMutation();
  const currentUser = useCurrentUser();
  const [newCommentContent, setNewCommentContent] = React.useState("");
  const editor = useEditor({
    extensions: [
      StarterKit,
      Link,
      Placeholder.configure({ placeholder: "Leave a comment..." }),
    ],
    onUpdate({ editor }) {
      setNewCommentContent(editor.getHTML());
    },
    content: "",
  });
  const theme = useMantineTheme();
  const handleSubmit = async () => {
    if (newCommentContent) {
      createCommentMut({
        variables: {
          conversationId: props.conversationId,
          transcriptPieceId: props.transcriptPieceId,
          text: newCommentContent,
          highlightedText: props.highlightedText,
          highlightedTextStart: 0,
          highlightedTextEnd: 10,
        },
      });
      setComments((prev) => [
        ...prev,
        {
          text: newCommentContent,
          user: currentUser.user!,
        },
      ]);
    }
  };
  const [comments, setComments] = React.useState(
    props.comments?.comments || []
  );
  return (
    <Box>
      <Transition
        mounted={props.visible}
        transition="fade"
        duration={400}
        timingFunction="ease"
      >
        {(styles) => (
          <Card shadow={"sm"} styles={styles}>
            <Flex justify="right">
              <ActionIcon onClick={props.onClose}>
                <IconX />
              </ActionIcon>
            </Flex>
            <Text weight={"bold"}>Highlighted Text:</Text>
            <Blockquote styles={{ body: { fontSize: "16px" } }}>
              {props.highlightedText}
            </Blockquote>
            {comments.map((comment, idx) => (
              <Box key={idx}>
                <Group>
                  <AuralizeAvatar user={comment.user} />
                  <SanitizeHTML html={comment.text} />
                </Group>
              </Box>
            ))}
            <Grid>
              <Grid.Col span="auto">
                <RichTextEditor
                  editor={editor}
                  placeholder="Leave a comment..."
                >
                  {editor && (
                    <BubbleMenu editor={editor}>
                      <RichTextEditor.ControlsGroup>
                        <RichTextEditor.Bold />
                        <RichTextEditor.Italic />
                        <RichTextEditor.Link />
                      </RichTextEditor.ControlsGroup>
                    </BubbleMenu>
                  )}
                  <RichTextEditor.Content />
                </RichTextEditor>
              </Grid.Col>
              <Grid.Col span="content" sx={{ alignSelf: "center" }}>
                <ActionIcon
                  onClick={handleSubmit}
                  color={theme.primaryColor[4]}
                  size="lg"
                  radius="lg"
                  variant="filled"
                >
                  <IconSend />
                </ActionIcon>
              </Grid.Col>
            </Grid>
          </Card>
        )}
      </Transition>
    </Box>
  );
}

export { CommentWidget };
