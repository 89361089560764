import * as React from "react";

import { Avatar, Box, Tooltip, Badge } from "@mantine/core";

import {
  MoodAngry,
  MoodAnnoyed,
  MoodEmpty,
  MoodSmile,
  MoodHappy,
} from "tabler-icons-react";

interface SentimentBadgeProps {
  sentiment: number;
}

function SentimentBadge(props: SentimentBadgeProps) {
  let avIcon = <></>;
  let sentimenText = "";
  let color = "";
  if (props.sentiment < -0.5) {
    avIcon = (
      <Avatar mr={5} radius="xl"  size={24}>
        <MoodAngry />
      </Avatar>
    );
    sentimenText = "Very Negative";
    color = "red.2";
  } else if (props.sentiment < -0.2) {
    avIcon = (
      <Avatar mr={5} radius="xl" size={24}>
        <MoodAnnoyed />
      </Avatar>
    );
    sentimenText = "Negative";
    color = "red.1";
  } else if (props.sentiment < 0.2) {
    avIcon = (
      <Avatar mr={5} radius="xl"  size={24}>
        <MoodEmpty />
      </Avatar>
    );
    sentimenText = "Neutral";
    let color = "gray.2";
  } else if (props.sentiment < 0.5) {
    avIcon = (
      <Avatar mr={5} radius="xl"  size={24}>
        <MoodSmile />
      </Avatar>
    );
    sentimenText = "Positive";
    let color = "teal.1";
  } else {
    avIcon = (
      <Avatar mr={5} radius="xl"  size={24}>
        <MoodHappy />
      </Avatar>
    );
    sentimenText = "Very Positive";
    let color = "teal";
  }

  return (
    <Tooltip label={`Customer Sentiment: ${props.sentiment.toFixed(2)}`}>
      <Badge
        sx={{ paddingLeft: 0 }}
        size="lg"
        bg={color}
        radius="xl"
        leftSection={avIcon}
      >
        {sentimenText}
      </Badge>
    </Tooltip>
  );
}

export { SentimentBadge };
