import * as React from "react";

export default function useSubdomain(position = 0, baseLength = 1) {
  const [subdomain] = React.useState(() => {
    try {
      const locations = window?.location?.hostname?.split(".");
      if(locations.length > baseLength) {
        return locations[position]
      }
      else {
        return null;
      }
    } catch (err: any) {
      console.error(err.message);
    }
  });

  return subdomain;
}
