import React from "react";

import { Grid } from "@mui/material";
import { useMantineTheme, Box, Divider, Text, Card, Mark } from "@mantine/core";
import { type CommentGroup } from "../molecules/conversations/types";


interface ChatBubbleProps {
  body: React.ReactNode;
  inbound: boolean;
  sentiment?: number | null;
  comments: CommentGroup[];
  focused?: boolean;
  toolbar?: React.ReactNode;
}

const ChatBubble = React.forwardRef<HTMLDivElement, ChatBubbleProps>(
  (props: ChatBubbleProps, ref) => {
    const theme = useMantineTheme();
    const bgColor = props.inbound
      ? theme.colors.gray[7]
      : theme.colors.gray[0];
    return (
      <Card
        ref={ref}
        sx={{
          backgroundColor: bgColor,
          color: props.inbound ? "white" : "black",
          borderRadius: props.inbound
            ? "0.8rem 0.8rem 0.8rem 0"
            : "0.8rem 0.8rem 0 0.8rem",
          minWidth: "25%",
          transform: props.focused ? `scale(1.01)` : "none",
        }}
      >
        <Grid container flexDirection="column" spacing={0.5}>
          <Grid item>{props.body}</Grid>
          {!!props.toolbar && (
            <Grid item>
              <Box m={0.5}>{props.toolbar}</Box>
            </Grid>
          )}
        </Grid>
      </Card>
    );
  }
);

export { ChatBubble };
