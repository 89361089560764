import {
  Card,
  Badge,
  Group,
  Grid,
  Tooltip,
  Text,
  ActionIcon,
  useMantineTheme,
  Anchor,
  Title,
} from "@mantine/core";
import { IconHeartHandshake } from "@tabler/icons";
import { Link } from "react-router-dom";

interface ThemeSummaryProps {
  themeName: string;
  conversationCount: number;
  baseUrl: string;
  themeId: string;
}

function ThemeSummary(props: ThemeSummaryProps) {
  const theme = useMantineTheme();
  return (
    <Card shadow="md" my={4}>
      <Grid align="center" justify="space-between">
        <Grid.Col span={9}>
          <Title order={5}>
            <Anchor
              component={Link}
              to={`${props.baseUrl}/${props.themeId}`}
            >
              {props.themeName}
            </Anchor>
          </Title>
        </Grid.Col>
        <Grid.Col span={3}>
          <Group position="right" w="100%">
            <Tooltip
              withArrow
              position="left"
              zIndex={10}
              label="Conversation Count"
            >
              <Badge
                leftSection={
                  <ActionIcon
                    size="xs"
                    color={theme.fn.primaryColor()}
                    radius="xl"
                    variant="transparent"
                  >
                    <IconHeartHandshake />
                  </ActionIcon>
                }
                size="lg"
              >
                {props.conversationCount}
              </Badge>
            </Tooltip>
          </Group>
        </Grid.Col>
      </Grid>
    </Card>
  );
}

export { ThemeSummary };
