import * as React from "react";

import { createStyles, keyframes, Text } from "@mantine/core";

import { useInterval } from "../../utils/useInterval";

const blinkCaret = keyframes({
  "from, to": { borderColor: "transparent" },
  "50%": { borderColor: "inherit" },
});

const useTypingAnmationStyles = createStyles({
  typewriter: {
    animation: `${blinkCaret} .75s step-end infinite`,
  },
});

interface TypingAnimationProps {
  text: string;
  typingFinishedContent?: React.ReactNode;
  typingSpeed: number;
}

function TypingAnimation(props: TypingAnimationProps) {
  const [length, setLength] = React.useState(0);
  const [interval, setInterval] = React.useState(props.typingSpeed);
  const [typingFinished, setTypingFinished] = React.useState(false);
  const classes = useTypingAnmationStyles();

  useInterval(() => {
    if (length === props.text.length) {
      setInterval(0);
      setTypingFinished(true);
    } else {
      setLength(length + 1);
    }
  }, interval);

  return (
    <>
      {!props.typingFinishedContent || !typingFinished ? (
        <Text>
          {props.text.slice(0, length)}
          <span className={classes.classes.typewriter}>
            {interval !== 0 ? "_" : ""}
          </span>
        </Text>
      ) : (
        <>{props.typingFinishedContent}</>
      )}
    </>
  );
}

export { TypingAnimation };
