import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { Box, Card, CardContent, Typography } from "@mui/material";

import { Visual } from "./visual";
import { PlainObject, VisualizationSpec } from "react-vega";

const VisualContainer = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>((props, ref) => {
  const spec: VisualizationSpec = {
    autosize: {
      type: "fit",
      contains: "padding",
    },
    mark: { type: "bar", tooltip: true },
    encoding: {
      x: { field: "a", type: "ordinal" },
      y: { field: "b", type: "quantitative" },
    },
    data: { name: "table" },
  };
  const data: PlainObject = {
    table: [
      { a: "A", b: 28 },
      { a: "B", b: 55 },
      { a: "C", b: 43 },
      { a: "D", b: 91 },
      { a: "E", b: 81 },
      { a: "F", b: 53 },
      { a: "G", b: 19 },
      { a: "H", b: 87 },
      { a: "I", b: 52 },
    ],
  };
  return (
    <div {...props} className={props.className} ref={ref}>
      <Box width="100%" height="100%" sx={{ userSelect: "none" }}>
        <Card sx={{ width: "100%", height: "100%" }}>
          <CardContent sx={{ width: "100%", height: "100%" }}>
            <Box>
              <Typography variant="h5" textAlign={"center"}>
                Title
              </Typography>
            </Box>
            <Box p={1} width="100%" height="100%">
              <Visual spec={spec} data={data} />
            </Box>
          </CardContent>
        </Card>
      </Box>
      {props.children}
    </div>
  );
});

const ResponsiveGridLayout = WidthProvider(Responsive);

interface DashboardProps {}

const Dashboard = () => {
  const layouts = {
    lg: [
      { i: "a", x: 0, y: 0, w: 6, h: 4, isBounded: true },
      { i: "b", x: 4, y: 5, w: 3, h: 3, isBounded: true },
      { i: "c", x: 0, y: 5, w: 4, h: 3, isBounded: true },
    ],
  };
  return (
    <Box width="100%" height="100%">
      <ResponsiveGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        rowHeight={60}
      >
        <VisualContainer key="a" />
        <VisualContainer key="b" />
        <VisualContainer key="c" />
      </ResponsiveGridLayout>
    </Box>
  );
};

const DashboardContainer = () => {
  return <Dashboard />;
};

export { DashboardContainer };
