import { createTheme } from "@mui/material";
import { Shadows } from "@mui/material/styles/shadows";

const shadow1 = "0px 10px 10px rgba(0, 0, 0, 0.1)";
const shadow2 = "0px 25px 30px rgba(0, 0, 0, 0.1)";
const shadow3 = "0px 30px 25px rgba(0, 0, 0, 0.2)";

const headingFontStack = "Raleway";
const bodyFontStack = "Raleway";


const theme = createTheme({
  palette: {
    primary: {
      main: "#427983",
    },
    secondary: {
      main: "#FFAD41",
      contrastText: "#fff",
    },
    tertiary: {
      main: "#7A28BC",
      light: "#7A28BC",
      dark:"#7A28BC",
      contrastText: "#fff"
    },
    quaternary: {
      main: "#00A6A6",
      light: "#00A6A6",
      dark: "#00A6A6",
      contrastText: "#fff"
    },
    contrast: {
      main: "#00072D",
      light: "#00072D",
      dark: "#00072D",
      contrastText: "#fff"
    },
  },
  typography: {
    fontFamily: bodyFontStack,
    fontSize: 16,
    fontWeightLight: 200,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    h1: {
      fontFamily: headingFontStack,
    },
    h2: {
      fontFamily: headingFontStack,
    },
    h3: {
      fontFamily: headingFontStack,
    },
    h4: {
      fontFamily: headingFontStack,
    },
    h5: {
      fontFamily: headingFontStack,
    },
    h6: {
      fontFamily: headingFontStack,
    },
    button: {
      fontFamily: headingFontStack,
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: { arrow: true },
    },
    MuiSwitch: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          width: 46,
          height: 26,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          "&$checked, &$colorPrimary$checked, &$colorSecondary$checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + $track": {
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: "1px solid #bdbdbd",
          backgroundColor: "#fafafa",
          opacity: 1,
          transition:
            "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "1em",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          border: 0,
          height: 48,
          textTransform: "none",
          padding: "0 30px",
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: "inset 0 -10px 10px -10px rgba(0, 0, 0, .4)",
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
});

const shadows = theme.shadows as Shadows;
shadows[1] = shadow1;
shadows[2] = shadow2;
shadows[3] = shadow3;

export { theme };
