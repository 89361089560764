import { useState } from "react";
import {
  TextInput,
  Button,
  Select,
  Stack,
  Box,
  Popover,
  useMantineTheme,
  Badge,
  ActionIcon,
  Autocomplete,
  Grid,
  Group,
  Skeleton,
} from "@mantine/core";
import {
  IconFilter,
  IconFilterOff,
  IconArrowsSort,
  IconX,
} from "@tabler/icons";
import {
  useListAttributesQuery,
  AttributeUse,
  AttributeModel,
} from "../../__generated__/operations";

type Operator =
  | "$eq"
  | "$ne"
  | "$gt"
  | "$gte"
  | "$lt"
  | "$lte"
  | "$in"
  | "$nin";

type FilterItem = {
  [key: string]: {
    [op: string]: string | number | boolean | (string | number | boolean)[];
  };
};

interface Filter {
  $and: FilterItem[];
}

const operators: {
  [op in Operator]: { label: string; value: Operator; badgeDisplay: string };
} = {
  $eq: { label: "Equal to", value: "$eq", badgeDisplay: "=" },
  $ne: { label: "Not equal to", value: "$ne", badgeDisplay: "≠" },
  $gt: { label: "Greater than", value: "$gt", badgeDisplay: "=" },
  $gte: { label: "Greater than or equal to", value: "$gte", badgeDisplay: "≥" },
  $lt: { label: "Less than", value: "$lt", badgeDisplay: "=" },
  $lte: { label: "Less than or equal to", value: "$lte", badgeDisplay: "≤" },
  $in: { label: "In list", value: "$in", badgeDisplay: " in " },
  $nin: { label: "Not in list", value: "$nin", badgeDisplay: " not in" },
};

interface FilterDialogProps {
  onAddFilter: (filterItem: FilterItem) => void;
  attributes: AttributeModel[];
}

function FilterDialog(props: FilterDialogProps): JSX.Element {
  const [attribute, setAttribute] = useState<string>("");
  const [operator, setOperator] = useState<Operator>(
    Object.values(operators)[0].value
  );
  const [value, setValue] = useState<string>("");

  const handleAttributeChange = (value: string) => {
    setAttribute(value);
  };
  const handleOperatorChange = (val: string) => {
    setOperator(val as Operator);
  };
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleAddFilter = () => {
    props.onAddFilter({
      [attribute]: { [operator]: value },
    });
  };
  const metadataOptions = props.attributes.map((attr) => ({
    value: attr.attributeName,
    group: "Metadata",
  }));
  const allAttributeOptions = [
    { value: "Handle Time", group: "Attributes" },
    { value: "Customer Sentiment", group: "Attributes" },
    { value: "Agent Sentiment", group: "Attributes" },
    { value: "Channel", group: "Attributes" },
    ...metadataOptions,
  ];
  console.log(allAttributeOptions);
  return (
    <div>
      <Stack spacing={1}>
        <Autocomplete
          value={attribute}
          label="Field"
          placeholder="Select"
          limit={300}
          maxDropdownHeight="300px"
          onChange={handleAttributeChange}
          data={allAttributeOptions}
          rightSection={
            attribute.length > 0 ? (
              <ActionIcon size="xs" onClick={() => setAttribute("")}>
                <IconX />
              </ActionIcon>
            ) : undefined
          }
        />
        <Select
          data={Object.values(operators)}
          label="Operator"
          placeholder="Select operator"
          value={operator}
          onChange={handleOperatorChange}
        />
        <TextInput label="Value" value={value} onChange={handleValueChange} />
        <Button variant="outline" onClick={handleAddFilter} mt={"sm"}>
          Add Filter
        </Button>
      </Stack>
    </div>
  );
}

function FilterSelector() {
  const theme = useMantineTheme();
  const [filters, setFilters] = useState<FilterItem[]>([]);
  const handleRemoveFilter = (index: number) => {
    const newFilters = JSON.parse(JSON.stringify(filters));
    newFilters.splice(index, 1);
    setFilters(newFilters);
  };
  const { data, loading, error } = useListAttributesQuery({
    variables: { use: AttributeUse.ConversationAttribute },
  });
  return (
    <Box my="sm">
      {loading && <Skeleton w="141px" h="36px" />}
      {!loading && (
        <Grid align="center">
          <Grid.Col span="auto">
            <Group>
              <Popover
                width={300}
                position="bottom"
                withArrow
                shadow="md"
                withinPortal
              >
                <Popover.Target>
                  <Button
                    variant="default"
                    radius="lg"
                    size="sm"
                    leftIcon={<IconFilter color={theme.colors.gray[5]} />}
                  >
                    Add Filter +
                  </Button>
                </Popover.Target>
                <Popover.Dropdown>
                  <FilterDialog
                    onAddFilter={(filter) => {
                      setFilters((prev) => [...prev, filter]);
                    }}
                    attributes={data?.AttributesByUse || []}
                  />
                </Popover.Dropdown>
              </Popover>
              {filters.map((filter, index) => (
                <Badge
                  sx={{
                    maxWidth: "240px",
                  }}
                  key={index}
                  size="lg"
                  rightSection={
                    <ActionIcon
                      size={"xs"}
                      onClick={() => handleRemoveFilter(index)}
                    >
                      <IconX />
                    </ActionIcon>
                  }
                >
                  <>
                    {Object.keys(filter)[0]}
                    {
                      operators[
                        Object.keys(
                          filter[Object.keys(filter)[0]]
                        )[0] as Operator
                      ].badgeDisplay
                    }
                    {Object.values(filter[Object.keys(filter)[0]])[0]}
                  </>
                </Badge>
              ))}
            </Group>
          </Grid.Col>
          <Grid.Col span="content">
            <Group>
              <ActionIcon>
                <IconFilterOff />
              </ActionIcon>
              <Popover
                width={300}
                position="bottom"
                withArrow
                shadow="md"
                withinPortal
              >
                <Popover.Target>
                  <ActionIcon>
                    <IconArrowsSort />
                  </ActionIcon>
                </Popover.Target>
                <Popover.Dropdown>Test</Popover.Dropdown>
              </Popover>
            </Group>
          </Grid.Col>
        </Grid>
      )}
    </Box>
  );
}

export { FilterSelector };
