import React, { FormEvent } from "react";
import {
  Skeleton,
  Card,
  Button,
  Grid,
  TextInput,
  Checkbox,
  ActionIcon,
  Text,
  Group,
  Box,
  Stack,
  PasswordInput,
  useMantineTheme,
  Alert,
  Avatar,
  Center,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import {
  useCurrentUserLazyQuery,
  useOrgBySubdomainQuery,
} from "../../__generated__/operations";

import {
  Route,
  Routes,
  useNavigate,
  Link,
  useParams,
  Navigate,
} from "react-router-dom";
import { Auth, Amplify, Hub } from "aws-amplify";
import { Logo } from "../../atoms/logo";
import { ArrowRight } from "tabler-icons-react";
import { redirect } from "react-router-dom";
import { UserModel, OrganizationModel } from "../../__generated__/operations";
import { FullPageLoading } from "../../atoms/fullPageLoading";
import useSubdomain from "../../utils/useSubdomain";

interface AuthProviderProps {
  children: React.ReactNode;
  redirectOnSignin?: string;
}

type FeatureFlags = { [key: string]: boolean };

interface CurrentUserContextState {
  signedIn: boolean;
  loading: boolean;
  username?: string;
  user?: UserModel;
  organization?: Pick<OrganizationModel, "organizationName" | "featureFlags">;
}

const currentUserContextInitialData: CurrentUserContextState = {
  signedIn: false,
  loading: true,
};

const CurrentUserContext = React.createContext({
  data: currentUserContextInitialData,
  setUser: (data: CurrentUserContextState) => {},
});

const authConfig = localStorage.getItem("savedAuthConfig");
if (authConfig) {
  Amplify.configure({
    Auth: JSON.parse(authConfig),
  });
}

const useCurrentUser = () => {
  const context = React.useContext(CurrentUserContext);
  return context.data;
};
const useCurrentUserContext = () => {
  const context = React.useContext(CurrentUserContext);
  return context;
};

const AuthProvider = (props: AuthProviderProps) => {
  const theme = useMantineTheme();
  const [currentUserQuery, currentUserQueryState] = useCurrentUserLazyQuery();
  const [currentUser, setCurrentUser] = React.useState<CurrentUserContextState>(
    { signedIn: false, loading: true }
  );
  const baseLength =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development" ? 1 : 2;
  const organization = useSubdomain(0, baseLength);

  const navigate = useNavigate();
  React.useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(async (user: any) => {
        const userQueryResult = await currentUserQuery();
        setCurrentUser({
          user: userQueryResult.data!.CurrentUser,
          organization: userQueryResult.data!.CurrentOrganization,
          username: user.usernam,
          signedIn: true,
          loading: false,
        });
      })
      .catch((err: any) => {
        setCurrentUser({
          signedIn: false,
          loading: false,
        });
      });
  }, []);
  return (
    <CurrentUserContext.Provider
      value={{
        data: currentUser,
        setUser: setCurrentUser,
      }}
    >
      {currentUser.signedIn ? props.children : undefined}
      {!currentUser.signedIn && !currentUser.loading ? (
        <Center>
          <Box mt={100} miw="40%">
            <Center>
              <Avatar
                radius={"50%"}
                size="xl"
                sx={(theme) => ({
                  backgroundColor: theme.white,
                })}
              >
                <Logo />
              </Avatar>
            </Center>
            <Card shadow="sm" w="100%" mt={10}>
              <Group>
                {organization && organization.length > baseLength ? (
                  <Routes>
                    <Route
                      path="/"
                      element={<Login organization={organization} />}
                    />
                    <Route
                      path="forgot-password"
                      element={<ChangePassword />}
                    />
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                ) : (
                  <OrgEntry />
                )}
              </Group>
            </Card>
          </Box>
        </Center>
      ) : undefined}
      {currentUser.loading ? <FullPageLoading /> : undefined}
    </CurrentUserContext.Provider>
  );
};

const OrgEntry = () => {
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const [org, setOrg] = React.useState("");
  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmit();
  };
  const handleButtonSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  const handleSubmit = () => {
    // eslint-disable-next-line no-restricted-globals
    const hostname = location.host;
    const win: Window = window;
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      win.location = `http://${org}.${hostname}`;
    } else {
      win.location = `https://${org}.${hostname}`;
    }
  };

  return (
    <Box w={"100%"} h="100%">
      <Stack spacing={1} align="stretch">
        <form onSubmit={handleFormSubmit}>
          <TextInput
            autoFocus
            value={org}
            onChange={(e) => {
              setOrg(e.target.value);
            }}
            label="Organization"
            name="organization"
            rightSection={
              <ActionIcon onClick={handleButtonSubmit}>
                <ArrowRight />
              </ActionIcon>
            }
          />
        </form>
      </Stack>
    </Box>
  );
};

interface LoginProps {
  organization: string;
}

const Login = (props: LoginProps) => {
  const navigate = useNavigate();
  const currentUserContext = useCurrentUserContext();
  const organization = props.organization;
  const { loading, error, data } = useOrgBySubdomainQuery({
    variables: { subdomain: organization! },
  });
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    signIn();
  };

  const [currentUserQuery, currentUserQueryState] = useCurrentUserLazyQuery();

  const signIn = async () => {
    if (data && username && password) {
      const authConfig = {
        region: data.OrganizationBySubdomain.userPoolRegion,
        userPoolId: data.OrganizationBySubdomain.userPoolId,
        userPoolWebClientId: data.OrganizationBySubdomain.userPoolClientId,
      };
      try {
        Amplify.configure({
          Auth: authConfig,
        });
        const authResponse = await Auth.signIn(username, password);
        localStorage.setItem("savedAuthConfig", JSON.stringify(authConfig));
        if (authResponse.challengeName === "NEW_PASSWORD_REQUIRED") {
          currentUserContext.setUser({
            signedIn: false,
            loading: false,
            username: authResponse.username,
            user: authResponse,
          });
          navigate("/change-password");
        } else {
          currentUserContext.setUser({
            signedIn: true,
            loading: false,
            username: authResponse.username,
            user: (await currentUserQuery()).data!.CurrentUser,
          });
          navigate("/");
        }
      } catch (error) {
        console.log("error signing in", error);
      }
    }
  };

  return (
    <Box w="100%">
      {error && (
        <Alert
          color={"red"}
          icon={<IconAlertCircle size="2rem" />}
          title="Oops!"
        >
          {error.message}
        </Alert>
      )}
      <form onSubmit={handleSubmit}>
        {loading && (
          <>
            <Skeleton width={"100%"} height={60} />
            <Skeleton width={"100%"} height={60} />
            <Stack>
              <Skeleton width={"100%"} height={27} />
              <Skeleton width={"100%"} height={27} />
            </Stack>
            <Skeleton width={"100%"} height={48} />
          </>
        )}
        {!loading && (
          <>
            <TextInput
              autoFocus
              value={username}
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              label="Username"
              name="username"
            />
            <PasswordInput
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              label="Password"
              name="password"
            />
            <Grid justify={"space-between"} w="100%" align={"center"}>
              <Grid.Col xs={6}>
                <Checkbox label="Remember Me" name="rememberme" />
              </Grid.Col>
              <Grid.Col xs={6} sx={{ textAlign: "right" }}>
                <Text component={Link} to="/forgot-password">
                  Forgot Password
                </Text>
              </Grid.Col>
            </Grid>
            <Button type="submit">Sign In</Button>
          </>
        )}
      </form>
    </Box>
  );
};

const ChangePassword = () => {
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const currentUserContext = useCurrentUserContext();

  const changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (confirmPassword === newPassword) {
      const changePasswordResponse = await Auth.completeNewPassword(
        currentUserContext.data.user,
        newPassword
      );
      currentUserContext.setUser({
        signedIn: true,
        loading: false,
        user: changePasswordResponse,
        username: changePasswordResponse.username,
      });
    }
  };
  return (
    <>
      {!currentUserContext.data.user && <Navigate to="/" />}
      <form onSubmit={changePassword}>
        <Stack spacing={1}>
          <Text>Update your password to continue.</Text>
          <PasswordInput
            value={newPassword}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
            label="New Password"
            name="new-password"
          />
          <PasswordInput
            value={confirmPassword}
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            label="Confirm Password"
            name="confirm-password"
          />
          <Button type="submit">Change Password</Button>
        </Stack>
      </form>
    </>
  );
};

export { AuthProvider, useCurrentUser, useCurrentUserContext };
