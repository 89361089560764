import * as React from "react";
import {
  Container,
  Card,
  TextInput,
  Button,
  Stack,
  Grid,
  Loader,
  Tabs,
  Text,
  Box,
  Center,
  useMantineTheme,
  Title,
  Drawer,
  Anchor,
  Divider,
} from "@mantine/core";
import { Link } from "react-router-dom";
import { IconSearch, IconQuestionMark } from "@tabler/icons";
import { FilterSelector } from "../../molecules/filters/filterSelector";
import {
  useSearchConversationsMutation,
  useMakeQaRequestMutation,
  MakeQaRequestMutation,
} from "../../__generated__/operations";
import { ConversationSummary } from "../../molecules/conversations/conversationSummary";
import { TypingAnimation } from "../../molecules/search/typingAnimation";

const Search = () => {
  const [searchConversationsMutation, search] =
    useSearchConversationsMutation();
  const [makeQaRequestMutation, qa] = useMakeQaRequestMutation();

  const [searchQuery, setSearchQuery] = React.useState("");
  const [qaQuery, setQaQuery] = React.useState("");
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [currentCitation, setCurrentCitation] = React.useState<
    MakeQaRequestMutation["MakeQaRequest"]["citations"][0] | null
  >(null);

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    await searchConversationsMutation({
      variables: { query: searchQuery },
    });
  };

  const handleQaRequest = async (e: React.FormEvent) => {
    e.preventDefault();
    await makeQaRequestMutation({
      variables: { query: qaQuery },
    });
  };

  const handleShowCitation = React.useCallback(
    (citationNum: string) => {
      setDrawerOpen(true);
      const citationIndex = parseInt(citationNum);
      if (qa.data?.MakeQaRequest) {
        setCurrentCitation(qa.data.MakeQaRequest.citations[citationIndex - 1]);
      }
    },
    [qa, setDrawerOpen, drawerOpen]
  );

  const buildCitations = (text: string) => {
    let reBracketsExtract = /\[(.*?)\]/g;
    let reBracketsSplit = /\[.*?\]/g;
    let matchedCitations: string[] = [];
    let found;
    while ((found = reBracketsExtract.exec(text))) {
      matchedCitations.push(found[1]);
    }
    const replacedCitations = text.replaceAll(
      reBracketsSplit,
      "~!~%CITATION%~!~"
    );
    let citationIndex = 0;
    return replacedCitations.split("~!~").map((s) => {
      if (s === "%CITATION%") {
        const citationStr = matchedCitations[citationIndex];
        const citationNums = citationStr.split(/[^0-9]+/);
        console.log(citationNums);
        citationIndex += 1;
        return (
          <sup>
            [
            {citationNums.map((citationNum, i) => {
              if (i === 0) {
                return (
                  <Anchor onClick={() => handleShowCitation(citationNum)}>
                    {citationNum}
                  </Anchor>
                );
              } else {
                return (
                  <>
                    {", "}
                    <Anchor onClick={() => handleShowCitation(citationNum)}>
                      {citationNum}
                    </Anchor>
                  </>
                );
              }
            })}
            ]
          </sup>
        );
      } else {
        return <span>{s}</span>;
      }
    });
  };

  const theme = useMantineTheme();
  return (
    <Container>
      <Tabs defaultValue={"search"} variant="outline">
        <Tabs.List mx={10} sx={{ borderBottomWidth: 0 }}>
          <Tabs.Tab
            sx={{ fontSize: "16px" }}
            value="search"
            icon={<IconSearch color={theme.fn.primaryColor()} />}
          >
            Search
          </Tabs.Tab>
          <Tabs.Tab
            sx={{ fontSize: "16px" }}
            value="qanda"
            icon={<IconQuestionMark color={theme.fn.primaryColor()} />}
          >
            Q&A
          </Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="search">
          <Card shadow={"md"}>
            <form onSubmit={handleSearch}>
              <Stack>
                <Grid grow>
                  <Grid.Col span={10}>
                    <TextInput
                      size="xl"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      autoFocus
                      icon={<IconSearch />}
                    />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Button size="xl" type="submit" w="100%">
                      Search
                    </Button>
                  </Grid.Col>
                </Grid>
              </Stack>

              <FilterSelector />
            </form>
          </Card>
        </Tabs.Panel>
        <Tabs.Panel value="qanda">
          <Card shadow={"md"}>
            <form onSubmit={handleQaRequest}>
              <Stack>
                <Grid grow>
                  <Grid.Col span={10}>
                    <TextInput
                      size="xl"
                      value={qaQuery}
                      onChange={(e) => setQaQuery(e.target.value)}
                      autoFocus
                      icon={<IconQuestionMark />}
                    />
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Button size="xl" type="submit" w="100%">
                      Answer
                    </Button>
                  </Grid.Col>
                </Grid>
              </Stack>

              <FilterSelector />
            </form>
          </Card>
        </Tabs.Panel>
        {(search.loading || qa.loading) && (
          <Box w="100%" mt={10}>
            <Center>
              <Loader variant="bars" />
            </Center>
          </Box>
        )}
        <Tabs.Panel value="search">
          {search.data?.SearchConversations.map((conv) => (
            <ConversationSummary {...conv} />
          ))}
        </Tabs.Panel>
        <Tabs.Panel value="qanda">
          {qa.data?.MakeQaRequest.answerText && (
            <Box w="100%" mt={10}>
              <Card shadow={"md"}>
                <Title>Answer:</Title>
                <TypingAnimation
                  text={qa.data?.MakeQaRequest.answerText}
                  typingSpeed={10}
                  typingFinishedContent={buildCitations(
                    qa.data?.MakeQaRequest.answerText
                  )}
                />
              </Card>
            </Box>
          )}
        </Tabs.Panel>
      </Tabs>
      <Drawer
        opened={drawerOpen}
        padding="md"
        size="md"
        withOverlay={false}
        title={<Title>Citations</Title>}
        onClose={() => setDrawerOpen(false)}
        position="right"
        shadow="md"
      >
        {currentCitation && (
          <>
            <ConversationSummary {...currentCitation.conversation} />
            <Divider variant="dashed" labelPosition="center" my="sm" label="MORE FROM THEME"/>
            {currentCitation.theme.conversations?.map((conversation) => (
              <ConversationSummary
                key={conversation.conversationId}
                {...conversation}
              />
            ))}
          </>
        )}
      </Drawer>
    </Container>
  );
};

export { Search };
