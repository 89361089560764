import { DataGrid, GridColDef, GridValidRowModel } from "@mui/x-data-grid";
import { QueryResult } from "@apollo/client";
import { Query, InputMaybe, Exact } from "../__generated__/operations";
import { Spread } from "../types/types";

// Extract only those queries that return arrays.
type Merge<A, B> = { [K in keyof (A | B)]: K extends keyof B ? B[K] : A[K] };
type FilterArrayKeys<T> = {
  [K in keyof T]: T[K] extends any[] ? K : never;
}[keyof T];
type ArrayKeys = NonNullable<FilterArrayKeys<Query>>;
type QueryBase = { __typename?: "Query" };
type ArrayQueriesType = {
  [Q in ArrayKeys]: Query[Q];
};
type ArrayQueryData = ArrayQueriesType[keyof ArrayQueriesType];
type ArrayQueries = Spread<[QueryBase, ArrayQueriesType]>;

interface ItemListProps<
P extends keyof Omit<ArrayQueries, "__typename">,
T extends { [key in P]: Omit<ArrayQueries, "__typename">[P] }
> {
  queryFunction: () => QueryResult<
    T,
    Exact<{
      skip?: InputMaybe<number> | undefined;
      take?: InputMaybe<number> | undefined;
    }>
  >;
  resultsKey: ArrayKeys;
  idColumn: string;
  columns: GridColDef[];
}

const ItemsList = <
  P extends keyof Omit<ArrayQueries, "__typename">,
  T extends { [key in P]: Omit<ArrayQueries, "__typename">[P] }
>(
  props: ItemListProps<P, T>
) => {
  const { data, loading, fetchMore } = props.queryFunction();
  return (
    <DataGrid
      columns={props.columns}
      disableSelectionOnClick
      disableColumnMenu
      loading={loading}
      // @ts-ignore: Untangling the types to get the right
      // keys would be a nightmare.
      rows={data?.[props.resultsKey] || []}
      // @ts-ignore: Untangling the types to get the right
      // keys would be a nightmare.
      getRowId={(row) => row[props.idColumn]}
    />
  );
};

export { ItemsList };
