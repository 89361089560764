import { MantineThemeOverride } from "@mantine/core";

const MANTINE_THEME: MantineThemeOverride = {
  colorScheme: "light",
  primaryColor: 'auralizeTeal',
  primaryShade: 6,
  colors: {
    auralizeTeal: [
      "#DEECEE",
      "#BAD5DA",
      "#9CBFC6",
      "#83ACB4",
      "#659CA7",
      "#518A95",
      "#427983",
      "#366A74",
      "#2C5D66",
      "#23515A"
    ]
  },

  shadows: {
    md: "1px 1px 3px rgba(0, 0, 0, .25)",
    xl: "5px 5px 3px rgba(0, 0, 0, .25)",
  },

  headings: {
    fontFamily: "Roboto, sans-serif",
    sizes: {
      h1: { fontSize: "30px" },
    },
  },
};

export { MANTINE_THEME };
