import * as React from "react";
import {
  List,
  ListItem,
  Card,
  CardContent,
  Typography,
  ListItemText,
  ListItemButton,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Box,
  ListItemIcon,
} from "@mui/material";
import { Link } from "react-router-dom";

import { usePlaylistByIdQuery } from "../../__generated__/operations";

import SkipPreviousIcon from "@mui/icons-material/SkipPrevious";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";

interface PlaylistWidgetProps {
  playlistId: string;
  conversationId?: string;
}

const PlaylistWidget = (props: PlaylistWidgetProps) => {
  const { loading, data } = usePlaylistByIdQuery({
    variables: { playlistId: props.playlistId },
  });
  const [currentIndex, setCurrentIndex] = React.useState<Number | undefined>(
    undefined
  );
  const prevButtonEnabled = currentIndex && currentIndex !== 0;
  const nextButtonEnabled =
    (data?.PlaylistWithAssignment &&
      currentIndex !== data.PlaylistWithAssignment.items!.length - 1) ||
    false;

  React.useEffect(() => {
    const newIndex = data?.PlaylistWithAssignment?.items?.findIndex(
      (item) => item.conversation?.conversationId === props.conversationId
    );
    setCurrentIndex(newIndex);
  }, [props.conversationId, data, setCurrentIndex]);

  return (
    <Box>
      {data?.PlaylistWithAssignment && (
        <Card>
          <CardContent>
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Typography fontWeight={"bold"}>
                  {data.PlaylistWithAssignment.name}
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Previous Item">
                  <span>
                    <IconButton
                      component={Link}
                      to={
                        prevButtonEnabled &&
                        data.PlaylistWithAssignment.items![
                          (currentIndex as number) - 1
                        ].conversation
                          ? `/conversations/${
                              data.PlaylistWithAssignment.items![
                                (currentIndex as number) - 1
                              ].conversation!.conversationId
                            }`
                          : "#"
                      }
                      size="small"
                      disabled={!prevButtonEnabled}
                    >
                      <SkipPreviousIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Next Item">
                  <span>
                    <IconButton
                      component={Link}
                      to={
                        prevButtonEnabled &&
                        data.PlaylistWithAssignment.items![
                          (currentIndex as number) + 1
                        ].conversation
                          ? `/conversations/${
                              data.PlaylistWithAssignment.items![
                                (currentIndex as number) + 1
                              ].conversation!.conversationId
                            }`
                          : "#"
                      }
                      size="small"
                      disabled={!nextButtonEnabled}
                    >
                      <SkipNextIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>

            <Divider />
            <List>
              {data.PlaylistWithAssignment.items &&
                data.PlaylistWithAssignment.items.map((item, index) => (
                  <ListItem
                    key={index}
                    disablePadding
                    selected={
                      item!.conversation!.conversationId ===
                      props.conversationId
                    }
                  >
                    <Tooltip title={item!.conversation!.name}>
                      <ListItemButton>
                        <ListItemIcon>
                          <PlayCircleFilledIcon />
                        </ListItemIcon>
                        <ListItemText
                          sx={{
                            span: {
                              fontWeight: "bold",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            },
                          }}
                        >
                          {item!.conversation!.name}
                        </ListItemText>
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                ))}
            </List>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};

export { PlaylistWidget };
