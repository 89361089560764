import { Menu, ActionIcon, Text, Indicator } from "@mantine/core";
import { IconBell } from "@tabler/icons";

function NotificationIcon() {
  return (
    <Menu
      position="bottom-end"
      withArrow
      arrowPosition="center"
      shadow={"md"}
      width={200}
    >
      <Menu.Target>
        <ActionIcon radius="xl" size="xl">
          <Indicator>
            <IconBell />
          </Indicator>
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <Text>0 Notifications</Text>
      </Menu.Dropdown>
    </Menu>
  );
}

export { NotificationIcon };
