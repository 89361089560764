import React, { createContext, useContext, useState, useEffect } from "react";
import { Condition } from "./condition";
import { useUncontrolled } from "@mantine/hooks";
import {
  Button,
  ActionIcon,
  Box,
  Select,
  Group,
  Stack,
  Flex,
  Card,
  Text,
  Input,
  TextInput,
  Textarea,
  Tooltip,
  Center,
} from "@mantine/core";
import { Trash, TrashX } from "tabler-icons-react";

const groupOperators = [
  { value: "AND", label: "all" },
  { value: "OR", label: "any" },
];

type ConditionType = {
  id: number;
  type: "condition" | "group";
};

export type ConditionsState = {
  operator: "AND" | "OR";
  conditions: ConditionType[];
};

export type ConditionsEditorState = { [key: string]: ConditionsState };

type ConditionsContextType = {
  state: ConditionsEditorState;
  setState: (value: { [key: string]: ConditionsState }) => void;
};

const ConditionsContext = createContext<ConditionsContextType | null>(null);

const useConditionGroup = () => {
  const context = useContext(ConditionsContext);
  if (!context) {
    throw new Error(
      "useConditionGroup must be used within a ConditionsContext.Provider"
    );
  }
  return context;
};

type ConditionGroupProps = {
  path: string;
  parent: string | null;
};

const ConditionGroup: React.FC<ConditionGroupProps> = ({ path, parent }) => {
  const { state, setState } = useConditionGroup();

  useEffect(() => {
    if (!state[path]) {
      setState({
        ...state,
        [path]: {
          operator: "AND",
          conditions: [],
        },
      });
    }
  }, [setState, path, state]);

  const addCondition = () => {
    const newConditions = state[path].conditions;
    setState({
      ...state,
      [path]: {
        ...state[path],
        conditions: [
          ...newConditions,
          { id: newConditions.length, type: "condition" },
        ],
      },
    });
  };

  const addGroup = () => {
    const newConditions = state[path].conditions;
    setState({
      ...state,
      [path]: {
        ...state[path],
        conditions: [...newConditions, { id: newConditions.length, type: "group" }],
      },
    });
  };

  const updateOperator = (value: "AND" | "OR") => {
    setState({
      ...state,
      [path]: { ...state[path], operator: value },
    });
  };

  const deleteGroup = () => {
    if (conditions.length > 0) {
      throw Error("Can't delete a group that still has conditions!");
    }
    if (!parent) {
      throw Error("Can't delete the root group!");
    }

    const parentConditions = state[parent!].conditions;
    const newState = { ...state };
    delete newState[path];

    setState({
      ...newState,
      [parent!]: {
        ...state[parent!],
        conditions: parentConditions.filter(
          (condition) => `${parent}.conditions.${condition.id}` !== path
        ),
      },
    });
  };

  const deleteCondition = (id: number) => {
    const currentConditions = state[path].conditions;
    setState({
      ...state,
      [path]: {
        ...state[path],
        conditions: currentConditions.filter(
          (condition) => condition.id !== id
        ),
      },
    });
  };

  const { operator, conditions } = state[path] || {
    operator: "AND",
    conditions: [],
  };

  return (
    <Box>
      <Flex align="stretch" m="sm" mt="lg">
        <Box
          w="20px"
          sx={(theme) => ({
            borderLeft: `1px solid ${theme.colors.gray[4]}`,
            borderTop: `1px solid ${theme.colors.gray[4]}`,
            borderBottom: `1px solid ${theme.colors.gray[4]}`,
            borderRadius: "10px 0px 0px 10px",
            marginRight: "2px",
          })}
        />
        <Stack>
          <Group spacing={6} mt={"-13px"} ml="8px">
            <Text>If </Text>
            <Select
              data={groupOperators}
              value={operator}
              size="xs"
              w="70px"
              onChange={(value) => updateOperator(value as "AND" | "OR")}
            />
            <Text> of the following conditions are true:</Text>
          </Group>
          <Box sx={{ marginBottom: 10 }} maw="1000px">
            {conditions.map((condition, index) => (
              <Box key={condition.id}>
                <Box>
                  {condition.type === "condition" ? (
                    <Group>
                      <Condition path={`${path}.conditions.${condition.id}`} />
                      <Center>
                        <Tooltip label={"Delete Condition"}>
                          <ActionIcon
                            onClick={() => deleteCondition(condition.id)}
                          >
                            <TrashX />
                          </ActionIcon>
                        </Tooltip>
                      </Center>
                    </Group>
                  ) : (
                    <ConditionGroup
                      path={`${path}.conditions.${condition.id}`}
                      parent={path}
                    />
                  )}
                </Box>
              </Box>
            ))}
            <Group mt="sm" spacing={2}>
              <Button size="xs" onClick={addCondition}>
                Add Condition
              </Button>
              <Button size="xs" onClick={addGroup} style={{ marginLeft: 10 }}>
                Add Group
              </Button>
            </Group>
          </Box>
        </Stack>
        {conditions.length === 0 && parent ? (
          <Center ml="sm">
            <Tooltip label={"Delete Group"}>
              <ActionIcon onClick={deleteGroup}>
                <TrashX />
              </ActionIcon>
            </Tooltip>
          </Center>
        ) : null}
      </Flex>
    </Box>
  );
};

interface ConditionsEditorProps {
  value?: ConditionsEditorState;
  defaultValue?: ConditionsEditorState;
  onChange?: (val: ConditionsEditorState) => void;
}

export const ConditionsEditor = ({
  value,
  defaultValue,
  onChange,
}: ConditionsEditorProps) => {
  const [_value, handleChange] = useUncontrolled<{
    [key: string]: ConditionsState;
  }>({
    value,
    defaultValue,
    finalValue: {},
    onChange,
  });

  return (
    <ConditionsContext.Provider
      value={{ state: _value, setState: handleChange }}
    >
      <ConditionGroup path="root" parent={null} />
    </ConditionsContext.Provider>
  );
};
