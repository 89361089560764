import React from "react";

import { Route, Routes } from "react-router-dom";

import { Home } from "./pages/home";
import { Conversation } from "./pages/conversations/conversation";
import { NavBar } from "./molecules/nav/navbar";
import { FormBuilder } from "./pages/evaluate/formBuilder";
import { DashboardContainer } from "./pages/analyze/dashboard";
import { VisualEditorContainer } from "./pages/analyze/visualEditor";
import { Notifications } from "./pages/notifications/notifications";
import { DashboardList } from "./pages/analyze/dashboardList";
import { Search } from "./pages/search/search";
import { Library } from "./pages/learn/library";

import { AppShell, Box } from "@mantine/core";

import {
  IconHome2,
  IconCompass,
  IconSearch,
  IconClipboardCheck,
  IconBook2,
  IconDeviceDesktopAnalytics,
  IconSettings,
} from "@tabler/icons";
import { ThemeInfo, ThemesList } from "./pages/explore/theme";
import { AuralizeHeader } from "./molecules/nav/header";
import { RuleEditor } from "./pages/evaluate/rules/ruleEditor";

const Layout = () => {
  return (
    <AppShell
      navbar={
        <NavBar
          routes={[
            {
              label: "Home",
              icon: IconHome2,
              section: "/",
              link: "/",
              subPages: null,
            },
            {
              label: "Search",
              icon: IconSearch,
              section: "/search",
              link: "/search",
              subPages: null,
            },
            {
              label: "Explore",
              icon: IconCompass,
              section: "/explore",
              link: "/explore",
              subPages: null,
            },
            {
              label: "Evaluate",
              icon: IconClipboardCheck,
              section: "/evaluate",
              link: "/evaluate/my-queue",
              subPages: [
                {
                  label: "Evaluation Queue",
                  link: "/evaluate/eval-queue",
                },
                {
                  label: "Forms",
                  link: "/evaluate/forms",
                },
                {
                  label: "Rules",
                  link: "/evaluate/rules",
                },
              ],
            },
            {
              label: "Learn",
              icon: IconBook2,
              section: "/learn",
              link: "/learn/assignments",
              subPages: [
                {
                  label: "My Assignments",
                  link: "/learn/assignments",
                },
                {
                  label: "Library",
                  link: "/learn/library",
                },
              ],
            },
            {
              label: "Analyze",
              icon: IconDeviceDesktopAnalytics,
              section: "/analyze",
              link: "/analyze/dashboards",
              subPages: [
                {
                  label: "Dashboards",
                  link: "/analyze/dashboards",
                },
                {
                  label: "Visuals",
                  link: "/analyze/visuals",
                },
              ],
            },
            {
              label: "Settings",
              icon: IconSettings,
              section: "/settings",
              link: "/settings",
              subPages: [
                {
                  label: "Users",
                  link: "/settings/users",
                },
                {
                  label: "Integrations",
                  link: "/settings/integrations",
                },
                {
                  label: "Queues",
                  link: "/settings/queues",
                },
              ],
              location: "bottom",
            },
          ]}
        />
      }
      header={<AuralizeHeader />}
    >
      <Box component="main" sx={{ zIndex: 1 }}>
        <Routes>
          <Route index element={<Home />} />

          <Route path="analyze">
            <Route path="dashboards" element={<DashboardList />} />
            <Route path="dashboards/:id" element={<DashboardContainer />} />
          </Route>

          <Route path="search" element={<Search />} />
          <Route path="explore">
            <Route index element={<ThemesList />} />
            <Route path="themes/:themeId" element={<ThemeInfo />} />
          </Route>

          <Route path="visuals">
            <Route path=":id" element={<VisualEditorContainer />} />
          </Route>

          <Route path="conversations">
            <Route path=":id" element={<Conversation />} />
          </Route>

          <Route path="learn">
            <Route path="library" element={<Library />} />
          </Route>

          <Route path="evaluate">
            <Route path="forms">
              <Route path="new" element={<FormBuilder />} />
            </Route>
            <Route path="rules" element={<RuleEditor />} />
          </Route>

          <Route path="notifications" element={<Notifications />} />
        </Routes>
      </Box>
    </AppShell>
  );
};

export default Layout;
