import React from "react";

import { Link, useLocation, matchPath } from "react-router-dom";

import {
  Tooltip,
  UnstyledButton,
  createStyles,
  Stack,
  Navbar,
  Flex,
  Box,
  useMantineTheme,
} from "@mantine/core";
import { TablerIcon } from "@tabler/icons";

import { Logo } from "../../atoms/logo";

const useStyles = createStyles((theme) => ({
  iconLink: {
    width: "50px",
    height: "50px",
    borderRadius: theme.radius.md,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  active: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
    },
  },

  link: {
    boxSizing: "border-box",
    display: "block",
    textDecoration: "none",
    borderTopRightRadius: theme.radius.md,
    borderBottomRightRadius: theme.radius.md,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    padding: `0px ${theme.spacing.md}px`,
    fontSize: theme.fontSizes.sm,
    marginRight: theme.spacing.md,
    fontWeight: 500,
    height: "44px",
    paddingLeft: "15px",
    lineHeight: "44px",

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  linkActive: {
    "&, &:hover": {
      borderLeftColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
      backgroundColor: theme.fn.variant({
        variant: "filled",
        color: theme.primaryColor,
      }).background,
      color: theme.white,
    },
  },
}));

function useRouteMatch(routes: Routes) {
  const { pathname } = useLocation();
  const patterns: string[] = [];

  for (const route of routes) {
    patterns.push(route.link);
    if (route.subPages) {
      for (const subPage of route.subPages) {
        patterns.push(subPage.link);
      }
    }
  }

  for (const pattern of patterns) {
    const possibleMatch = matchPath(pattern, pathname);
    if (possibleMatch !== null) {
      return possibleMatch;
    }
  }

  return null;
}

function useRouteTopLevelMatch(routes: string[]) {
  const { pathname } = useLocation();

  for (const route of routes) {
    const possibleMatch = matchPath(route + "/*", pathname);
    if (possibleMatch !== null) {
      return route;
    }
  }

  return null;
}

type Routes = {
  icon: TablerIcon;
  label: string;
  link: string;
  section: string;
  location?: "top" | "bottom";
  subPages:
    | {
        label: string;
        link: string;
      }[]
    | null;
}[];

interface NavBarProps {
  routes: Routes;
}

const useNavbarWidth = () => {};

const NavBar = (props: NavBarProps) => {
  const routeMatch = useRouteMatch(props.routes);
  const topRouteMatch = useRouteTopLevelMatch(
    props.routes.map((navLink) => navLink.section)
  );
  const currentRoute = props.routes.find(
    (navLink) => navLink.section === topRouteMatch
  );
  const secondaryMatch = routeMatch?.pattern?.path;
  const theme = useMantineTheme();
  const mainLinks = props.routes
    .filter((route) => route.location !== "bottom")
    .map((link, index) => (
      <IconNavbarLink
        {...link}
        key={link.label}
        active={link.section === topRouteMatch}
      />
    ));

  const bottomLinks = props.routes
    .filter((route) => route.location === "bottom")
    .map((link, index) => (
      <IconNavbarLink
        {...link}
        key={link.label}
        active={link.section === topRouteMatch}
      />
    ));

  const secondaryLinks = currentRoute?.subPages?.map((link) => (
    <NavbarLink
      label={link.label}
      link={link.link}
      key={link.label}
      active={link.link === secondaryMatch}
    />
  ));

  const mainWidth = 80;
  const secondaryWidth = 200;
  const totalWidth = mainWidth + secondaryWidth;
  const navBarWidth = currentRoute?.subPages
    ? `${totalWidth}px`
    : `${mainWidth}px`;

  return (
    <Navbar height={"100%"} width={{ base: navBarWidth }}>
      <Flex w={navBarWidth} h={"100%"}>
        <Navbar.Section>
          <Stack
            justify="space-between"
            h="94%"
            sx={{
              borderRight: `1px solid ${
                theme.colorScheme === "dark"
                  ? theme.colors.dark[7]
                  : theme.colors.gray[3]
              }`,
            }}
          >
            <Box p="md" w={{ base: mainWidth }}>
              <Box>
                <Stack justify="center" spacing={0}>
                  {mainLinks}
                </Stack>
              </Box>
            </Box>
            <Box
              p="md"
              w={{ base: mainWidth }}
            >
              <Box>
                <Stack justify="center" spacing={0}>
                  {bottomLinks}
                </Stack>
              </Box>
            </Box>
          </Stack>
        </Navbar.Section>

        {currentRoute?.subPages ? (
          <Navbar.Section
            w={{ base: `${secondaryWidth}px` }}
            h="100%"
            sx={{
              borderRight: `1px solid ${
                theme.colorScheme === "dark"
                  ? theme.colors.dark[7]
                  : theme.colors.gray[3]
              }`,
              height: "100%",
            }}
          >
            <Stack
              justify="center"
              w={{ base: `${secondaryWidth}px` }}
              mt="30px"
              spacing={0}
            >
              {secondaryLinks}
            </Stack>
          </Navbar.Section>
        ) : undefined}
      </Flex>
    </Navbar>
  );
};

interface IconNavbarLinkProps {
  icon: TablerIcon;
  label: string;
  active?: boolean;
  link: string;
}

function IconNavbarLink({
  icon: Icon,
  label,
  active,
  link,
}: IconNavbarLinkProps) {
  const { classes, cx } = useStyles();
  return (
    <Tooltip label={label} position="right" transitionProps={{ duration: 0 }}>
      <UnstyledButton
        component={Link}
        to={link}
        className={cx(classes.iconLink, { [classes.active]: active })}
      >
        <Icon stroke={1.5} />
      </UnstyledButton>
    </Tooltip>
  );
}

interface NavbarLinkProps {
  label: string;
  active?: boolean;
  link: string;
}

function NavbarLink({ label, active, link }: NavbarLinkProps) {
  const { classes, cx } = useStyles();
  return (
    <UnstyledButton
      component={Link}
      to={link}
      className={cx(classes.link, { [classes.active]: active })}
    >
      {label}
    </UnstyledButton>
  );
}

export { NavBar };
