import React from "react";
import { Select, TextInput, Stack, Box, Group } from "@mantine/core";

const operators = [
  { value: "equals", label: "Equals" },
  { value: "not_equals", label: "Not equals" },
];

const fields = [
  { value: "field1", label: "Field 1" },
  { value: "field2", label: "Field 2" },
];

interface ConditionProps {
    path: string;
}

export const Condition: React.FC<ConditionProps> = (props: ConditionProps) => {
  return (
    <Group>
      <Box>
        <Select data={fields} label="Field" placeholder="Select a field" />
      </Box>
      <Box>
        <Select
          data={operators}
          label="Operator"
          placeholder="Select an operator"
        />
      </Box>
      <Box>
        <TextInput label="Value" placeholder="Enter a value" />
      </Box>
    </Group>
  );
};
