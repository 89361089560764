import { Auth } from 'aws-amplify';

async function signOut() {
    try {
        await Auth.signOut();
        window.location.reload();
    } catch (error) {
        console.log('error signing out: ', error);
    };
}

export { signOut };