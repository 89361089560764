import React from "react";
import {
  Box,
  Grid,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { Mark } from "vega-lite/src/mark";
import { VisualizationSpec, PlainObject } from "react-vega";

import { Visual } from "./visual";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const defaultSpec = {
  autosize: {
    type: "fit",
    contains: "padding",
  },
  mark: { type: "bar", tooltip: true },
  encoding: {
    x: { field: "a", type: "ordinal" },
    y: { field: "b", type: "quantitative", aggregate: "sum" },
  },
  data: { name: "table" },
} as const;

const VisualEditor = () => {
  const [chartType, setChartType] = React.useState<Mark>(defaultSpec.mark.type);
  // TODO: Improve the types here.
  const [spec, setSpec] = React.useState<any>(defaultSpec);
  const data: PlainObject = {
    table: [
      { a: "A", b: 28 },
      { a: "A", b: 28 },
      { a: "B", b: 55 },
      { a: "C", b: 43 },
      { a: "D", b: 91 },
      { a: "E", b: 81 },
      { a: "F", b: 53 },
      { a: "G", b: 19 },
      { a: "H", b: 87 },
      { a: "I", b: 52 },
    ],
  };
  const rebuildSpec = () => {
    const newSpec = {
      ...spec,
    };
    const newMark = {
      type: chartType,
      tooltip: true,
    };
    newSpec.mark = newMark;
    setSpec(newSpec);
  };
  React.useEffect(() => {
    rebuildSpec();
  }, [chartType, rebuildSpec]);
  return (
    <Box>
      <Grid container spacing={1}>
        <Grid item md={3} xs={12}>
          <Card>
            <CardContent>
              <Accordion sx={{ boxShadow: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Basic Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <FormControl fullWidth>
                    <InputLabel id="chart-type-label">Chart Type</InputLabel>
                    <Select<Mark>
                      labelId="chart-type-label"
                      id="chart-type"
                      value={chartType}
                      label="Chart Type"
                      onChange={(e) => {setChartType(e.target.value as Mark)}}
                    >
                      <MenuItem value={"bar"}>Bar Chart</MenuItem>
                      <MenuItem value={"line"}>Line Chart</MenuItem>
                      <MenuItem value={"area"}>Area Chart</MenuItem>
                    </Select>
                  </FormControl>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Chart Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ boxShadow: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography>Filters</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={6} xs={12}>
          <Card>
            <CardContent>
              <Box width={"100%"} height="80vh">
                <Visual spec={spec} data={data} />
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

const VisualEditorContainer = () => {
  return <VisualEditor />;
};

export { VisualEditorContainer };
