import React from "react";
import { VegaLite, View as VegaView, VisualizationSpec, PlainObject } from "react-vega";
import { Box } from "@mui/material";
import { useResizeDetector } from "react-resize-detector";

import halogenTheme from "../../theming/vegaTheme";

interface VisualProps {
    spec: VisualizationSpec;
    data: PlainObject;
}

const Visual = (props: VisualProps) => {
  const { width, height, ref } = useResizeDetector();
  const vegaView = React.useRef<VegaView | undefined>();

  React.useEffect(() => {
    if (vegaView.current) {
      vegaView.current.resize();
    }
  }, [width, height]);

  const handleNewView = (view: VegaView) => {
    vegaView.current = view;
  };
  return (
    <Box width="100%" height="100%" ref={ref}>
      <VegaLite
        onNewView={handleNewView}
        width={width || 1}
        height={height || 1}
        spec={props.spec}
        data={props.data}
        actions={false}
        config={halogenTheme}
      />
    </Box>
  );
};

export { Visual };