import React from "react";

import { Avatar, Box, Tooltip } from "@mantine/core";

import {
  MoodAngry,
  MoodAnnoyed,
  MoodEmpty,
  MoodSmile,
  MoodHappy,
} from "tabler-icons-react";

interface SentimentIconProps {
  sentiment: number;
  tooltip?: boolean;
}

const SentimentIcon = (props: SentimentIconProps) => {
  let button = <></>;
  let sentimenText = "";
  if (props.sentiment < -0.5) {
    button = (
      <Avatar radius="xl">
        <MoodAngry />
      </Avatar>
    );
    sentimenText = "Very Negative";
  } else if (props.sentiment < -0.2) {
    button = (
      <Avatar radius="xl">
        <MoodAnnoyed />
      </Avatar>
    );
    sentimenText = "Negative";
  } else if (props.sentiment < 0.2) {
    button = (
      <Avatar radius="xl">
        <MoodEmpty />
      </Avatar>
    );
    sentimenText = "Neutral";
  } else if (props.sentiment < 0.5) {
    button = (
      <Avatar radius="xl">
        <MoodSmile />
      </Avatar>
    );
    sentimenText = "Positive";
  } else {
    button = (
      <Avatar radius="xl">
        <MoodHappy />
      </Avatar>
    );
    sentimenText = "Very Positive";
  }

  if (props.tooltip) {
    return (
      <Box sx={{ cursor: "pointer" }}>
        <Tooltip label={`Sentiment: ${sentimenText}`}>{button}</Tooltip>
      </Box>
    );
  } else {
    return <>{button}</>;
  }
};

export { SentimentIcon };
