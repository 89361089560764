import { Center, Loader, Overlay, Stack } from "@mantine/core";
import { Logo } from "./logo";

function FullPageLoading() {
  return (
    <Overlay color="#fff" fixed opacity={1}>
      <Center h="100%">
        <Stack>
          <Logo />
          <Loader variant="dots" />
        </Stack>
      </Center>
    </Overlay>
  );
}

export { FullPageLoading };
