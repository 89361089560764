import React from "react";

import {
  Avatar,
  Box,
  Center,
  Chip,
  Flex,
  Group,
  Text,
  Portal,
  Card,
  Button,
  Mark,
} from "@mantine/core";
import { useTextSelection } from "@mantine/hooks";
import { User, Headset } from "tabler-icons-react";
import { SentimentIcon } from "../../atoms/sentimentIconButton";
import {
  ConversationByIdQuery,
  ConversationCategoryAssociationModel,
} from "../../__generated__/operations";
import { ChatBubble } from "../../atoms/chatBubble";
import { type CommentGroup, type QueryTranscriptPiecesType } from "./types";
import { IconMessagePlus } from "@tabler/icons";

interface SelectionMenuProps {
  y: number;
  x: number;
  visible: boolean;
  onAddComment: () => void;
}

function SelectionMenu(props: SelectionMenuProps) {
  const menuRef = React.useRef<HTMLDivElement>(null);
  const left =
    props.x - (menuRef.current?.getBoundingClientRect().width || 0) / 2;
  const top = props.y - (menuRef.current?.getBoundingClientRect().height || 0);

  return (
    <Portal>
      <Box
        ref={menuRef}
        sx={{
          display: props.visible ? "block" : "none",
          position: "fixed",
          zIndex: 1000,
          top: props.y,
          left,
          userSelect: "none",
        }}
      >
        <Card shadow="md" p={2}>
          <Button
            leftIcon={<IconMessagePlus />}
            onClick={props.onAddComment}
            variant="subtle"
          >
            Add Comment
          </Button>
        </Card>
      </Box>
    </Portal>
  );
}

interface ChatContainerRowProps {
  body: string;
  inbound: boolean;
  sentiment?: number | null;
  comments: CommentGroup[];
  categories: ConversationCategoryAssociationModel[];
  id: string;
  handleOpenComments: (
    highlightedText: string,
    transcriptPieceId: string,
    comments: CommentGroup | null
  ) => void;
  focused?: boolean;
}

const ChatContainerRow = (props: ChatContainerRowProps) => {
  const chatBubbleRef = React.useRef<HTMLDivElement>(null);
  const selection = useTextSelection();
  const selectionText = selection?.toString();
  const selected =
    selection &&
    selection.toString() &&
    chatBubbleRef.current?.contains(selection?.focusNode) &&
    props?.body.includes(selection.toString());

  const [selectionMenuY, setSelectionMenuY] = React.useState(0);
  const [selectionMenuX, setSelectionMenuX] = React.useState(0);

  React.useEffect(() => {
    const Y_OFFSET = 45;
    if (selected) {
      const selectionRect = selection.getRangeAt(0).getBoundingClientRect();
      setSelectionMenuX(selectionRect.left + selectionRect.width / 2);
      setSelectionMenuY(selectionRect.top - Y_OFFSET);
    }
  }, [selectionText]);

  const handleAddCommentClick = React.useCallback(() => {
    props.handleOpenComments(selectionText || "", props.id, null);
  }, [selectionText]);

  const handleOpenComment = React.useCallback(
    (idx: number) => {
      console.log(props.comments);
      props.handleOpenComments(
        props.comments[idx].comments[0].text,
        props.id,
        props.comments[idx]
      );
    },
    [props]
  );

  return (
    <Flex w={"100%"} mt={6} direction={props.inbound ? "row" : "row-reverse"}>
      <SelectionMenu
        visible={!!selected}
        x={selectionMenuX}
        y={selectionMenuY}
        onAddComment={handleAddCommentClick}
      />
      <Flex direction="column" justify={"flex-end"} m={3}>
        <Avatar radius="xl">
          {!props.inbound && <Headset />}
          {props.inbound && <User />}
        </Avatar>
      </Flex>
      <Flex w={"100%"} direction={props.inbound ? "row" : "row-reverse"}>
        <Box sx={{ maxWidth: "85%" }}>
          <ChatBubble
            focused={props.focused}
            body={
              <Text>
                {props.comments.length > 0 ? (
                  <>
                    {props.comments.map((c, idx) => (
                      <>
                        <Mark
                          sx={{ cursor: "pointer" }}
                          onClick={() => handleOpenComment(idx)}
                        >
                          {props.body.substring(
                            c.highlightStart,
                            c.highlightEnd
                          )}
                        </Mark>
                        {props.body.substring(
                          c.highlightEnd,
                          props.comments[idx + 1]?.highlightEnd ||
                            props.body.length
                        )}
                      </>
                    ))}
                  </>
                ) : (
                  props.body
                )}
              </Text>
            }
            sentiment={props.sentiment}
            inbound={props.inbound}
            comments={props.comments}
            ref={chatBubbleRef}
            toolbar={
              <Group>
                {props.categories.map((category, index) => (
                  <Chip
                    sx={{ maxWidth: "160px", marginX: 0.2 }}
                    color="tertiary"
                    checked={false}
                  >
                    <Flex align="center" h="100%">
                      <Text size="12px" weight="bold">
                        {category.categoryName}
                      </Text>
                    </Flex>
                  </Chip>
                ))}
              </Group>
            }
          />
        </Box>
        <Box sx={{ maxWidth: "15%" }}>
          <Flex direction="column" h="100%" justify={"center"} m={3}>
            <SentimentIcon tooltip={true} sentiment={props.sentiment || 0} />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

interface ChatContainerProps {
  conversationId: string;
  transcript: ConversationByIdQuery["Conversation"]["transcriptPieces"];
  focusedTranscriptPiece?: string;
  scrollYOffset?: number;
  autoScroll?: boolean;
  handleAddComment: (
    highlightedText: string,
    transcriptPieceId: string,
    comments: CommentGroup | null
  ) => void;
}

const ChatContainer = (props: ChatContainerProps) => {
  React.useEffect(() => {
    if (props.focusedTranscriptPiece && props.autoScroll) {
      const el = document.getElementById(
        props.focusedTranscriptPiece.toString()
      );
      if (el) {
        const y =
          el.getBoundingClientRect().top +
          window.pageYOffset +
          (props?.scrollYOffset || 0);
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [props.focusedTranscriptPiece, props.scrollYOffset, props.autoScroll]);
  return (
    <>
      <Box m={1} w="100%">
        {props.transcript?.map((transcriptPiece, index) => (
          <>
            {transcriptPiece.comments && (
              <ChatContainerRow
                handleOpenComments={props.handleAddComment}
                key={index}
                id={transcriptPiece.transcriptPieceId}
                inbound={transcriptPiece.speakerType === "CUSTOMER"}
                body={transcriptPiece.text}
                sentiment={transcriptPiece.sentiment}
                categories={transcriptPiece.categories}
                comments={[]}
                focused={
                  props.focusedTranscriptPiece ===
                  transcriptPiece.transcriptPieceId
                }
              />
            )}
          </>
        ))}
      </Box>
    </>
  );
};

export { ChatContainer, ChatBubble };
