import React from "react";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api/apolloClient";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import "./augmentation";

import Layout from "./layout";
import { theme } from "./theming/muiTheme";
import { MANTINE_THEME } from "./theming/mantineTheme";

import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider, useCurrentUser } from "./pages/auth/auth";
import { MantineProvider } from "@mantine/core";
import { ThemeProvider } from "@mui/material";


const App = () => (
  <MantineProvider withGlobalStyles withNormalizeCSS theme={MANTINE_THEME}>
    <ThemeProvider theme={theme}>
      <Router>
        <ApolloProvider client={apolloClient}>
          <AuthProvider>
            <Layout />
          </AuthProvider>
        </ApolloProvider>
      </Router>
    </ThemeProvider>
  </MantineProvider>
);

export default App;
