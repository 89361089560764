import * as React from "react";
import { Anchor } from "@mantine/core";
import { Link as RouterLink } from "react-router-dom";

interface LinkProps {
  to: string;
  children: React.ReactNode
}

function Link(props: LinkProps) {
  return <Anchor component={RouterLink} to={props.to}>{props.children}</Anchor>;
}

export { Link };
