import * as React from "react";
import { Box, Input, ActionIcon, Group, Title } from "@mantine/core";
import { IconCheck, IconDotsCircleHorizontal, IconPencil } from "@tabler/icons";

type EditableHeadingStatus = "EDITING" | "SAVING" | "VIEWING";

interface EditableHeadingProps {
  text: string;
  status: EditableHeadingStatus;
  handleSave: (text: string) => void;
  handleEdit: () => void;
  ariaDescription: string;
  editButtonAriaDescription: string;
  saveButtonAriaDescription: string;
}

function EditableHeading(props: EditableHeadingProps) {
  const [text, setText] = React.useState(props.text);
  return (
    <Box>
      {["EDITING", "SAVING"].includes(props.status) && (
        <Group spacing={2}>
          <Input
            autoFocus
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          {props.status === "EDITING" && (
            <ActionIcon
              onClick={() => {
                props.handleSave(text);
              }}
            >
              <IconCheck />
            </ActionIcon>
          )}
          {props.status === "SAVING" && (
            <ActionIcon>
              <IconDotsCircleHorizontal />
            </ActionIcon>
          )}
        </Group>
      )}
      {props.status === "VIEWING" && (
        <Group spacing={2}>
          <Title variant="h4">{props.text}</Title>
          <ActionIcon
            onClick={() => {
              props.handleEdit();
            }}
          >
            <IconPencil />
          </ActionIcon>
        </Group>
      )}
    </Box>
  );
}

export { EditableHeading, type EditableHeadingStatus };
