import * as React from "react";
import {
  Container,
  Card,
  Title,
  Loader,
  Group,
  Stack,
  Text,
  ActionIcon,
  TextInput,
  Grid,
  Button,
} from "@mantine/core";
import {
  SearchThemesMutation,
  useConversationThemesLazyQuery,
  useSearchThemesMutation,
  useThemeByIdQuery,
} from "../../__generated__/operations";
import { ThemeSummary } from "../../molecules/themes/themeSummary";
import { useParams } from "react-router";
import { ConversationSummary } from "../../molecules/conversations/conversationSummary";
import { IconArrowLeft, IconSearch, IconX } from "@tabler/icons";
import { Link } from "react-router-dom";

const ThemesList = () => {
  const [makeSearchThemeRequest, themeSearch] = useSearchThemesMutation();
  const cursor = React.useRef<string | undefined>(undefined);
  const [listThemesQuery, themesListQueryResp] =
    useConversationThemesLazyQuery();
  type ThemeType = SearchThemesMutation["SearchThemes"];
  const [themesList, setThemesList] = React.useState<ThemeType>([]);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchMode, setSearchMode] = React.useState(false);
  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    setThemesList([]);
    cursor.current = undefined;
    setSearchMode(true);
    const result = await makeSearchThemeRequest({
      variables: { query: searchQuery },
    });
    if (!result.errors && result.data) {
      setThemesList(result.data!.SearchThemes!);
    }
  };

  const handleClearSearch = () => {
    setSearchMode(false);
    setSearchQuery("");
    setThemesList([]);
    cursor.current = undefined;
    handleListThemes();
  }

  const handleListThemes = async () => {
    const result = await listThemesQuery({
      variables: {
        categoryName: "ISSUE",
        cursor: cursor.current,
      },
    });
    const resultsLength = result?.data?.ListThemes.length || 0;
    if (!result.error && resultsLength > 0) {
      console.log(result);
      console.log(cursor.current);
      if (cursor.current) {
        setThemesList((prev) => [...prev, ...result.data!.ListThemes]);
      } else {
        setThemesList(result.data!.ListThemes);
      }
      cursor.current = result.data!.ListThemes[resultsLength - 1].themeId;
    }
  };

  React.useEffect(() => {
    handleListThemes();
  }, []);

  const isLoading = themesListQueryResp.loading || themeSearch.loading;
  const isError = !!themesListQueryResp.error || !!themeSearch.error;

  return (
    <Container>
      <Card shadow={"md"}>
        <Title>Themes</Title>
        <form onSubmit={handleSearch}>
          <Stack>
            <Grid grow>
              <Grid.Col span={10}>
                <TextInput
                  size="md"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  icon={<IconSearch />}
                  rightSection={
                    searchQuery ? (
                      <ActionIcon onClick={handleClearSearch} size="xs" variant="subtle">
                        <IconX />
                      </ActionIcon>
                    ) : undefined
                  }
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Button size="md" type="submit" w="100%" variant="outline">
                  Search
                </Button>
              </Grid.Col>
            </Grid>
          </Stack>
        </form>
      </Card>
      {isLoading && !themesList ? (
        <Group position="center" w="100%" my={6}>
          <Loader />
        </Group>
      ) : undefined}
      {themesList && !isError
        ? themesList.map((theme) => (
            <ThemeSummary
              themeName={theme.name}
              conversationCount={theme.conversationCount}
              themeId={theme.themeId}
              baseUrl="/explore/themes"
              key={theme.themeId}
            />
          ))
        : undefined}
      {themesList && !isError && !searchMode ? (
        <Button
          loading={isLoading}
          fullWidth
          variant="light"
          onClick={handleListThemes}
        >
          Load More
        </Button>
      ) : undefined}
    </Container>
  );
};

const ThemeInfo = () => {
  const { themeId } = useParams();
  const { data, loading, error } = useThemeByIdQuery({
    variables: {
      themeId: themeId!,
    },
  });
  return (
    <Container>
      <Card shadow="md">
        <Stack spacing={0}>
          <Group spacing={1}>
            <ActionIcon component={Link} to="/explore">
              <IconArrowLeft />
            </ActionIcon>
            <Text tt="uppercase" c="dimmed">
              Theme
            </Text>
          </Group>
          <Title>{data?.ThemeById.name}</Title>
        </Stack>
      </Card>
      {data?.ThemeById.conversations?.map((conversation) => (
        <ConversationSummary
          conversationId={conversation.conversationId}
          name={conversation.name}
          highlightedText={conversation.highlightedText}
          sentimentCustomer={conversation.sentimentCustomer}
          key={conversation.conversationId}
        />
      ))}
    </Container>
  );
};

export { ThemesList, ThemeInfo };
