import { Header, Flex, Menu, ActionIcon, Group, Indicator } from "@mantine/core";
import { IconBell } from "@tabler/icons";
import { AuralizeAvatar } from "../../atoms/auralizeAvatar";
import { Logo } from "../../atoms/logo";
import { NotificationIcon } from "../../atoms/notificationIcon";
import { useCurrentUser } from "../../pages/auth/auth";

import { signOut } from "../../utils/signout";

function AuralizeHeader() {
  const user = useCurrentUser();
  return (
    <Header height={60}>
      <Flex h="100%" justify="space-between">
        <Flex w="80px" h="100%" justify="center" align="center">
          <Logo />
        </Flex>
        <Flex h="100%" mx="lg" align="center">
          <Group>
            <NotificationIcon />
            <Menu
              position="bottom-end"
              withArrow
              arrowPosition="center"
              shadow={"md"}
              width={100}
            >
              <Menu.Target>
                <ActionIcon size="xl">
                  <AuralizeAvatar user={user!.user} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item onClick={signOut}>Logout</Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Flex>
      </Flex>
    </Header>
  );
}

export { AuralizeHeader };
