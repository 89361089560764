import React from "react";

import { WaveSurfer, WaveForm, Region } from "wavesurfer-react";
import {
  ActionIcon,
  Slider,
  Group,
  Box,
  Text,
  Transition,
  useMantineTheme,
  Grid,
  Center,
  Stack,
  Flex,
} from "@mantine/core";
import {
  IconVolumeOff,
  IconVolume,
  IconVolume2,
  IconVolume3,
  IconPlayerPlay,
  IconPlayerPause,
  IconZoomIn,
  IconZoomOut,
} from "@tabler/icons";
import RegionsPlugin, {
  Region as RegionElement,
} from "wavesurfer.js/src/plugin/regions";
import wavesurferjs from "wavesurfer.js";
import { Duration } from "luxon";

import { TranscriptPieceModel } from "../../__generated__/operations";

interface AudioPlayerProps {
  src: string;
  transcript?:
    | Omit<TranscriptPieceModel, "conversation" | "comments" | "categories">[]
    | null;
  toolbar?: JSX.Element;
  onTranscriptPieceFocus?: (transcriptPieceId: string) => void;
}

const AudioPlayer = (props: AudioPlayerProps) => {
  const [playing, setPlaying] = React.useState(false);
  const ZOOM_MIN = 1;
  const ZOOM_MAX = 6;
  const ZOOM_STEP = 0.5;
  const [zoom, setZoom] = React.useState(ZOOM_MIN);

  const VOLUME_MIN = 0;
  const VOLUME_MAX = 100;
  const [volumeSliderDisplay, setVolumeSliderDisplay] = React.useState(false);
  const [volumeLevel, setVolumeLevel] = React.useState(100);
  const [mute, setMute] = React.useState(false);

  const volumeSliderTimer = React.useRef<
    ReturnType<typeof setTimeout> | undefined
  >();

  const wavesurferRef = React.useRef<wavesurferjs | null>(null);
  const wavesurferContainerRef = React.useRef<HTMLDivElement | null>(null);
  const theme = useMantineTheme();

  const transcriptIdMap: { [id: string]: TranscriptPieceModel } | undefined =
    props.transcript?.reduce(
      (prev, transcriptPiece) => ({
        ...prev,
        [transcriptPiece.transcriptPieceId]: transcriptPiece,
      }),
      {}
    );

  React.useEffect(() => {
    if (volumeSliderTimer.current) {
      clearTimeout(volumeSliderTimer.current);
    }
    volumeSliderTimer.current = setTimeout(() => {
      setVolumeSliderDisplay(false);
    }, 3000);

    return () => {
      clearTimeout(volumeSliderTimer.current);
    };
  }, [volumeLevel, volumeSliderDisplay]);

  React.useEffect(() => {
    wavesurferRef.current?.setVolume(volumeLevel / 100);
  }, [volumeLevel]);

  React.useEffect(() => {
    wavesurferRef.current?.setMute(mute);
  }, [mute]);

  React.useEffect(() => {
    if (playing && wavesurferRef.current) {
      wavesurferRef.current.play();
    }
    if (!playing && wavesurferRef.current) {
      wavesurferRef.current.pause();
    }
  }, [playing]);

  React.useEffect(() => {
    if (wavesurferRef.current && wavesurferContainerRef.current) {
      const width = wavesurferContainerRef.current.offsetWidth;
      const duration = wavesurferRef.current.getDuration();
      if (duration > 0) {
        const basePxPerSecond = width / duration;
        const zoomAmt = basePxPerSecond * zoom;
        wavesurferRef.current.zoom(zoomAmt);
      }
    }
  }, [zoom]);

  const regionInit = (ev: RegionElement) => {
    const speakerRole = transcriptIdMap?.[ev.id]?.speakerType;
    // Apply bottom borders indicating speaker
    ev.element.style.borderBottom = `4px solid ${
      speakerRole === "CUSTOMER"
        ? theme.primaryColor
        : theme.colors.lime[4]
    }`;

    // Add speaker to tooltip
    // @ts-ignore: Types are wrong here, this should be updatable.
    ev.formatTimeCallback = (start: number, end: number) => {
      const speaker = speakerRole;
      const startTime = Duration.fromObject({ seconds: start }).toFormat(
        "mm:ss"
      );
      const endTime = Duration.fromObject({ seconds: end }).toFormat("mm:ss");
      return `${speaker} - ${startTime}-${endTime}`;
    };
  };

  return (
    <Grid>
      <Grid.Col span={1} h="100%" sx={{ alignSelf: "center" }}>
        <Center>
          <ActionIcon
            radius="60px"
            size="60px"
            variant="filled"
            color={theme.primaryColor[4]}
            onClick={() => {
              setPlaying(!playing);
            }}
          >
            {playing && <IconPlayerPause />}
            {!playing && <IconPlayerPlay />}
          </ActionIcon>
        </Center>
      </Grid.Col>
      <Grid.Col span={11}>
        <Stack spacing={0}>
          <Box mx={1} ref={wavesurferContainerRef}>
            <WaveSurfer
              onMount={(wavesurfer) => {
                wavesurferRef.current = wavesurfer;
                if (wavesurferRef.current) {
                  wavesurferRef.current.load(props.src);
                  wavesurferRef.current.on("region-created", regionInit);
                }
              }}
              plugins={[
                {
                  plugin: RegionsPlugin,
                  options: { dragSelection: false },
                },
              ]}
            >
              <WaveForm
                id="waveform"
                cursorColor="transparent"
                height={80}
                barWidth={3.5}
                barRadius={4}
                cursorWidth={2}
                progressColor={theme.primaryColor[3]}
                waveColor={theme.primaryColor[2]}
              ></WaveForm>
              {props.transcript?.map((transcriptPiece) => {
                if (transcriptPiece.startTime && transcriptPiece.endTime) {
                  return (
                    <Region
                      onIn={() => {
                        if (props.onTranscriptPieceFocus) {
                          props.onTranscriptPieceFocus(
                            transcriptPiece.transcriptPieceId
                          );
                        }
                      }}
                      key={transcriptPiece.transcriptPieceId}
                      id={transcriptPiece.transcriptPieceId.toString()}
                      start={transcriptPiece.startTime}
                      end={transcriptPiece.endTime}
                      color={"rgba(0, 0, 0, 0.02)"}
                      drag={false}
                      resize={false}
                      data={{}}
                    />
                  );
                } else {
                  return undefined;
                }
              })}
            </WaveSurfer>
          </Box>
          <Flex justify="space-between">
            <Group spacing={4}>
              <Box> {props.toolbar}</Box>
              <Group sx={{ width: 250 }}>
                <ActionIcon
                  onMouseEnter={() => {
                    setVolumeSliderDisplay(true);
                  }}
                  onClick={() => {
                    setMute((prev) => !prev);
                  }}
                >
                  {mute && <IconVolumeOff />}
                  {!mute && (
                    <>
                      {volumeLevel <= 10 && <IconVolume3 />}
                      {10 < volumeLevel && volumeLevel < 50 && <IconVolume2 />}
                      {volumeLevel >= 50 && <IconVolume />}
                    </>
                  )}
                </ActionIcon>

                <Transition
                  mounted={volumeSliderDisplay}
                  transition="scale-x"
                  timingFunction="ease"
                >
                  {(styles) => (
                    <Box style={styles} w="120px">
                      <Group w={"100%"} spacing={0}>
                        <Slider
                          min={VOLUME_MIN}
                          max={VOLUME_MAX}
                          w="80%"
                          onChange={(val) => setVolumeLevel(val as number)}
                          value={volumeLevel}
                          label={null}
                        />
                        <Text w="10%" pl="4px">
                          {volumeLevel}%
                        </Text>
                      </Group>
                    </Box>
                  )}
                </Transition>
              </Group>
            </Group>
            <Group spacing={0}>
              <ActionIcon
                onClick={() => {
                  zoom > ZOOM_MIN && setZoom(zoom - ZOOM_STEP);
                }}
              >
                <IconZoomOut />
              </ActionIcon>
              <Box pl={1} w={"80px"}>
                <Slider
                  value={zoom}
                  w="100%"
                  onChange={(val) => {
                    setZoom(val as number);
                  }}
                  min={ZOOM_MIN}
                  max={ZOOM_MAX}
                  label={null}
                />
              </Box>

              <ActionIcon
                onClick={() => {
                  zoom < ZOOM_MAX && setZoom(zoom + ZOOM_STEP);
                }}
              >
                <IconZoomIn />
              </ActionIcon>
            </Group>
          </Flex>
        </Stack>
      </Grid.Col>
    </Grid>
  );
};

export { AudioPlayer };
