import {
  Box,
  Card,
  Text,
  Divider
} from "@mantine/core";

function EvaluationWidget() {
  return (
    <Box>
      <Card shadow="sm">
          <Text weight={"bold"}>Evaluate</Text>
          <Divider />
      </Card>
    </Box>
  );
}

export { EvaluationWidget };
