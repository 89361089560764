import sanitizeHtml from "sanitize-html";

const ALLOWED_TAGS = [
  "abbr",
  "acronym",
  "b",
  "blockquote",
  "br",
  "code",
  "div",
  "em",
  "i",
  "li",
  "ol",
  "p",
  "span",
  "strong",
  "table",
  "td",
  "tr",
  "ul",
];

const defaultOptions: sanitizeHtml.IOptions = {
  allowedTags: ALLOWED_TAGS,
  allowedAttributes: {
    a: ["href"],
  },
};

const sanitize = (dirty: string, options?: sanitizeHtml.IOptions) => ({
  __html: sanitizeHtml(dirty, { ...defaultOptions, ...(options || {}) }),
});

function SanitizeHTML({
  html,
  options,
}: {
  html: string;
  options?: sanitizeHtml.IOptions;
}) {
  return <div dangerouslySetInnerHTML={sanitize(html, options)} />;
}

export { SanitizeHTML };
