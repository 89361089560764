import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_HALOGEN_API_URI,
});
console.log(process.env.REACT_APP_HALOGEN_API_URI);

const authLink = setContext(async (_, { headers, ...context }) => {
  try {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();
    const token = accessToken.getJwtToken();

    return {
      headers: {
        ...headers,
        ...(token ? {authorization: `Bearer ${token}`} : {}),
      },
      ...context
    };
  } catch {
    return context;
  }

});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export { apolloClient };
