import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AttributeModel = {
  __typename?: 'AttributeModel';
  attributeId: Scalars['String'];
  attributeName: Scalars['String'];
  attributeType: Scalars['String'];
};

export enum AttributeType {
  BoolType = 'BOOL_TYPE',
  DateType = 'DATE_TYPE',
  FloatType = 'FLOAT_TYPE',
  IntegerType = 'INTEGER_TYPE',
  StringType = 'STRING_TYPE'
}

export enum AttributeUse {
  ConversationAttribute = 'CONVERSATION_ATTRIBUTE',
  FormAttribute = 'FORM_ATTRIBUTE'
}

export type Citation = {
  __typename?: 'Citation';
  conversation: ConversationModel;
  theme: ConversationThemeModel;
};

export type ConversationCategoryAssociationModel = {
  __typename?: 'ConversationCategoryAssociationModel';
  categoryName: Scalars['String'];
  text: Scalars['String'];
};

export enum ConversationChannel {
  Chat = 'CHAT',
  Email = 'EMAIL',
  FbMessenger = 'FB_MESSENGER',
  GoogleMeet = 'GOOGLE_MEET',
  Instagram = 'INSTAGRAM',
  Review = 'REVIEW',
  Slack = 'SLACK',
  Sms = 'SMS',
  Teams = 'TEAMS',
  Telegram = 'TELEGRAM',
  Twitter = 'TWITTER',
  TwitterDm = 'TWITTER_DM',
  Voice = 'VOICE',
  Webex = 'WEBEX',
  Wechat = 'WECHAT',
  Whatsapp = 'WHATSAPP',
  Zoom = 'ZOOM'
}

export type ConversationCommentModel = {
  __typename?: 'ConversationCommentModel';
  commentId: Scalars['String'];
  conversation: ConversationModel;
  highlightedText?: Maybe<Scalars['String']>;
  highlightedTextEnd?: Maybe<Scalars['Int']>;
  highlightedTextStart?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  transcriptPiece: TranscriptPieceModel;
  user: UserModel;
};

export type ConversationModel = {
  __typename?: 'ConversationModel';
  channel?: Maybe<Scalars['String']>;
  conversationId: Scalars['String'];
  createdAt: Scalars['String'];
  highlightedText?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  recordingPresignedUrl?: Maybe<Scalars['String']>;
  recordingS3Key?: Maybe<Scalars['String']>;
  sentimentAgentEmployee?: Maybe<Scalars['Float']>;
  sentimentCustomer?: Maybe<Scalars['Float']>;
  sentimentOverall?: Maybe<Scalars['Float']>;
  transcriptPieces?: Maybe<Array<TranscriptPieceModel>>;
};

export type ConversationThemeModel = {
  __typename?: 'ConversationThemeModel';
  categoryName: Scalars['String'];
  conversationCount: Scalars['Int'];
  conversations?: Maybe<Array<ConversationModel>>;
  name: Scalars['String'];
  themeId: Scalars['String'];
};

export type DashboardModel = {
  __typename?: 'DashboardModel';
  dashboardId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export enum IncludeType {
  All = 'ALL',
  Read = 'READ',
  Unread = 'UNREAD'
}

export type Mutation = {
  __typename?: 'Mutation';
  AddCategoryToConversation: ConversationCategoryAssociationModel;
  AddComment: ConversationCommentModel;
  AddPlaylistConversation: PlaylistAssociationModel;
  AdminCreateUser: UserModel;
  CreateDashboard: DashboardModel;
  CreateOrganization: OrganizationModel;
  CreatePlaylist: PlaylistModel;
  CreatePlaylistFolder: PlaylistFolderModel;
  CreateUser: UserModel;
  DeleteUser: UserModel;
  EditConversationName: ConversationModel;
  MakeQaRequest: QaAnswerModel;
  SearchConversations: Array<ConversationModel>;
  SearchThemes: Array<ConversationThemeModel>;
};


export type MutationAddCategoryToConversationArgs = {
  categoryName: Scalars['String'];
  conversationId: Scalars['String'];
  text: Scalars['String'];
  transcriptPieceId: Scalars['String'];
};


export type MutationAddCommentArgs = {
  conversationId: Scalars['String'];
  highlightedText?: InputMaybe<Scalars['String']>;
  highlightedTextEnd?: InputMaybe<Scalars['Int']>;
  highlightedTextStart?: InputMaybe<Scalars['Int']>;
  text: Scalars['String'];
  transcriptPieceId: Scalars['String'];
};


export type MutationAddPlaylistConversationArgs = {
  conversationId: Scalars['String'];
  playlistId: Scalars['String'];
};


export type MutationAdminCreateUserArgs = {
  email: Scalars['String'];
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  organizationId: Scalars['String'];
  tempPassword: Scalars['String'];
  username: Scalars['String'];
};


export type MutationCreateDashboardArgs = {
  dashboardDefintion: Scalars['String'];
  name: Scalars['String'];
};


export type MutationCreateOrganizationArgs = {
  organizationName: Scalars['String'];
  primaryEmail: Scalars['String'];
  subdomain: Scalars['String'];
};


export type MutationCreatePlaylistArgs = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationCreatePlaylistFolderArgs = {
  name: Scalars['String'];
  parentFolderId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String'];
  familyName?: InputMaybe<Scalars['String']>;
  givenName?: InputMaybe<Scalars['String']>;
  tempPassword: Scalars['String'];
  username: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  username: Scalars['String'];
};


export type MutationEditConversationNameArgs = {
  conversationId: Scalars['String'];
  name: Scalars['String'];
};


export type MutationMakeQaRequestArgs = {
  query: Scalars['String'];
};


export type MutationSearchConversationsArgs = {
  query: Scalars['String'];
};


export type MutationSearchThemesArgs = {
  query: Scalars['String'];
};

export type NotificationModel = {
  __typename?: 'NotificationModel';
  notificationLink: Scalars['String'];
  notificationText: Scalars['String'];
  user: UserModel;
};

export type OrganizationModel = {
  __typename?: 'OrganizationModel';
  featureFlags: Scalars['String'];
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  subdomain: Scalars['String'];
  subscription: SubscriptionModel;
  userPoolClientId: Scalars['String'];
  userPoolId: Scalars['String'];
  userPoolRegion: Scalars['String'];
};

export type PlaylistAssignmentModel = {
  __typename?: 'PlaylistAssignmentModel';
  playlist: PlaylistModel;
  user: UserModel;
};

export enum PlaylistAssignmentProgressStatus {
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS'
}

export type PlaylistAssociationModel = {
  __typename?: 'PlaylistAssociationModel';
  conversation?: Maybe<ConversationModel>;
  progress?: Maybe<PlaylistAssignmentProgressStatus>;
};

export type PlaylistFolderModel = {
  __typename?: 'PlaylistFolderModel';
  name?: Maybe<Scalars['String']>;
  parentFolder?: Maybe<PlaylistFolderModel>;
  playlists?: Maybe<Array<PlaylistModel>>;
  subfolders?: Maybe<Array<PlaylistFolderModel>>;
};

export type PlaylistModel = {
  __typename?: 'PlaylistModel';
  description?: Maybe<Scalars['String']>;
  items?: Maybe<Array<PlaylistAssociationModel>>;
  name: Scalars['String'];
  playlistId: Scalars['String'];
};

export type QaAnswerModel = {
  __typename?: 'QaAnswerModel';
  answerText: Scalars['String'];
  citations: Array<Citation>;
};

export type Query = {
  __typename?: 'Query';
  AttributesByUse: Array<AttributeModel>;
  Conversation: ConversationModel;
  CurrentOrganization: OrganizationModel;
  CurrentUser: UserModel;
  Dashboard: DashboardModel;
  ListDashboards: Array<DashboardModel>;
  ListThemes: Array<ConversationThemeModel>;
  MyNotifications: Array<NotificationModel>;
  OrganizationBySubdomain: OrganizationModel;
  PlaylistFolder: PlaylistFolderModel;
  PlaylistWithAssignment: PlaylistModel;
  ThemeById: ConversationThemeModel;
  User: UserModel;
};


export type QueryAttributesByUseArgs = {
  use: AttributeUse;
};


export type QueryConversationArgs = {
  conversationId: Scalars['String'];
};


export type QueryDashboardArgs = {
  dashboardId: Scalars['String'];
};


export type QueryListDashboardsArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryListThemesArgs = {
  categoryName: Scalars['String'];
  count?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
};


export type QueryMyNotificationsArgs = {
  include?: IncludeType;
};


export type QueryOrganizationBySubdomainArgs = {
  subdomain: Scalars['String'];
};


export type QueryPlaylistFolderArgs = {
  folderId: Scalars['String'];
};


export type QueryPlaylistWithAssignmentArgs = {
  playlistId: Scalars['String'];
};


export type QueryThemeByIdArgs = {
  themeId: Scalars['String'];
};


export type QueryUserArgs = {
  username: Scalars['String'];
};

export enum SpeakerType {
  Agent = 'AGENT',
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE'
}

export type SubscriptionModel = {
  __typename?: 'SubscriptionModel';
  subscriptionId: Scalars['String'];
  type: SubscriptionType;
};

export enum SubscriptionType {
  Enterprise = 'ENTERPRISE',
  Inactive = 'INACTIVE',
  Standard = 'STANDARD',
  Trial = 'TRIAL'
}

export type TranscriptPieceModel = {
  __typename?: 'TranscriptPieceModel';
  categories: Array<ConversationCategoryAssociationModel>;
  comments: Array<ConversationCommentModel>;
  endTime?: Maybe<Scalars['Int']>;
  sentiment?: Maybe<Scalars['Float']>;
  speakerId: Scalars['String'];
  speakerType: SpeakerType;
  startTime?: Maybe<Scalars['Int']>;
  text: Scalars['String'];
  transcriptPieceId: Scalars['String'];
};

export type UserModel = {
  __typename?: 'UserModel';
  email: Scalars['String'];
  familyName?: Maybe<Scalars['String']>;
  givenName?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type ListAttributesQueryVariables = Exact<{
  use: AttributeUse;
}>;


export type ListAttributesQuery = { __typename?: 'Query', AttributesByUse: Array<{ __typename?: 'AttributeModel', attributeId: string, attributeName: string, attributeType: string }> };

export type CreateCommentMutationVariables = Exact<{
  conversationId: Scalars['String'];
  transcriptPieceId: Scalars['String'];
  text: Scalars['String'];
  highlightedText?: InputMaybe<Scalars['String']>;
  highlightedTextStart?: InputMaybe<Scalars['Int']>;
  highlightedTextEnd?: InputMaybe<Scalars['Int']>;
}>;


export type CreateCommentMutation = { __typename?: 'Mutation', AddComment: { __typename?: 'ConversationCommentModel', commentId: string, text: string, highlightedText?: string | null, highlightedTextStart?: number | null, highlightedTextEnd?: number | null, user: { __typename?: 'UserModel', username: string, familyName?: string | null, givenName?: string | null, email: string } } };

export type SearchConversationsMutationVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchConversationsMutation = { __typename?: 'Mutation', SearchConversations: Array<{ __typename?: 'ConversationModel', conversationId: string, name: string, highlightedText?: string | null, sentimentOverall?: number | null, sentimentCustomer?: number | null, sentimentAgentEmployee?: number | null }> };

export type SearchThemesMutationVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchThemesMutation = { __typename?: 'Mutation', SearchThemes: Array<{ __typename?: 'ConversationThemeModel', themeId: string, name: string, categoryName: string, conversationCount: number }> };

export type EditConversationNameMutationVariables = Exact<{
  conversationId: Scalars['String'];
  name: Scalars['String'];
}>;


export type EditConversationNameMutation = { __typename?: 'Mutation', EditConversationName: { __typename?: 'ConversationModel', conversationId: string, name: string } };

export type ConversationByIdQueryVariables = Exact<{
  conversationId: Scalars['String'];
}>;


export type ConversationByIdQuery = { __typename?: 'Query', Conversation: { __typename?: 'ConversationModel', conversationId: string, name: string, createdAt: string, channel?: string | null, recordingS3Key?: string | null, recordingPresignedUrl?: string | null, sentimentCustomer?: number | null, sentimentAgentEmployee?: number | null, sentimentOverall?: number | null, transcriptPieces?: Array<{ __typename?: 'TranscriptPieceModel', transcriptPieceId: string, speakerId: string, speakerType: SpeakerType, startTime?: number | null, endTime?: number | null, sentiment?: number | null, text: string, categories: Array<{ __typename?: 'ConversationCategoryAssociationModel', categoryName: string, text: string }>, comments: Array<{ __typename?: 'ConversationCommentModel', commentId: string, text: string, user: { __typename?: 'UserModel', username: string, familyName?: string | null, givenName?: string | null, email: string } }> }> | null } };

export type ConversationThemesQueryVariables = Exact<{
  categoryName: Scalars['String'];
  cursor?: InputMaybe<Scalars['String']>;
}>;


export type ConversationThemesQuery = { __typename?: 'Query', ListThemes: Array<{ __typename?: 'ConversationThemeModel', themeId: string, name: string, categoryName: string, conversationCount: number }> };

export type ThemeByIdQueryVariables = Exact<{
  themeId: Scalars['String'];
}>;


export type ThemeByIdQuery = { __typename?: 'Query', ThemeById: { __typename?: 'ConversationThemeModel', themeId: string, name: string, categoryName: string, conversationCount: number, conversations?: Array<{ __typename?: 'ConversationModel', conversationId: string, name: string, highlightedText?: string | null, sentimentOverall?: number | null, sentimentCustomer?: number | null, sentimentAgentEmployee?: number | null }> | null } };

export type DashboardByIdQueryVariables = Exact<{
  dashboardId: Scalars['String'];
}>;


export type DashboardByIdQuery = { __typename?: 'Query', Dashboard: { __typename?: 'DashboardModel', dashboardId?: string | null, name?: string | null } };

export type ListDashboardsQueryVariables = Exact<{
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
}>;


export type ListDashboardsQuery = { __typename?: 'Query', ListDashboards: Array<{ __typename?: 'DashboardModel', dashboardId?: string | null, name?: string | null }> };

export type MyNotificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyNotificationsQuery = { __typename?: 'Query', MyNotifications: Array<{ __typename?: 'NotificationModel', notificationText: string, notificationLink: string, user: { __typename?: 'UserModel', username: string, familyName?: string | null, givenName?: string | null } }> };

export type OrgBySubdomainQueryVariables = Exact<{
  subdomain: Scalars['String'];
}>;


export type OrgBySubdomainQuery = { __typename?: 'Query', OrganizationBySubdomain: { __typename?: 'OrganizationModel', organizationId: string, organizationName: string, subdomain: string, userPoolId: string, userPoolClientId: string, userPoolRegion: string, featureFlags: string } };

export type PlaylistByIdQueryVariables = Exact<{
  playlistId: Scalars['String'];
}>;


export type PlaylistByIdQuery = { __typename?: 'Query', PlaylistWithAssignment: { __typename?: 'PlaylistModel', playlistId: string, name: string, description?: string | null, items?: Array<{ __typename?: 'PlaylistAssociationModel', progress?: PlaylistAssignmentProgressStatus | null, conversation?: { __typename?: 'ConversationModel', name: string, conversationId: string } | null }> | null } };

export type MakeQaRequestMutationVariables = Exact<{
  query: Scalars['String'];
}>;


export type MakeQaRequestMutation = { __typename?: 'Mutation', MakeQaRequest: { __typename?: 'QaAnswerModel', answerText: string, citations: Array<{ __typename?: 'Citation', conversation: { __typename?: 'ConversationModel', conversationId: string, name: string, highlightedText?: string | null, sentimentOverall?: number | null, sentimentCustomer?: number | null, sentimentAgentEmployee?: number | null }, theme: { __typename?: 'ConversationThemeModel', themeId: string, conversations?: Array<{ __typename?: 'ConversationModel', conversationId: string, name: string, highlightedText?: string | null, sentimentOverall?: number | null, sentimentCustomer?: number | null, sentimentAgentEmployee?: number | null }> | null } }> } };

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename?: 'Query', CurrentUser: { __typename?: 'UserModel', familyName?: string | null, givenName?: string | null, username: string, email: string }, CurrentOrganization: { __typename?: 'OrganizationModel', organizationName: string, featureFlags: string } };


export const ListAttributesDocument = gql`
    query ListAttributes($use: AttributeUse!) {
  AttributesByUse(use: $use) {
    attributeId
    attributeName
    attributeType
  }
}
    `;

/**
 * __useListAttributesQuery__
 *
 * To run a query within a React component, call `useListAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAttributesQuery({
 *   variables: {
 *      use: // value for 'use'
 *   },
 * });
 */
export function useListAttributesQuery(baseOptions: Apollo.QueryHookOptions<ListAttributesQuery, ListAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAttributesQuery, ListAttributesQueryVariables>(ListAttributesDocument, options);
      }
export function useListAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAttributesQuery, ListAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAttributesQuery, ListAttributesQueryVariables>(ListAttributesDocument, options);
        }
export type ListAttributesQueryHookResult = ReturnType<typeof useListAttributesQuery>;
export type ListAttributesLazyQueryHookResult = ReturnType<typeof useListAttributesLazyQuery>;
export type ListAttributesQueryResult = Apollo.QueryResult<ListAttributesQuery, ListAttributesQueryVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($conversationId: String!, $transcriptPieceId: String!, $text: String!, $highlightedText: String, $highlightedTextStart: Int, $highlightedTextEnd: Int) {
  AddComment(
    conversationId: $conversationId
    transcriptPieceId: $transcriptPieceId
    text: $text
    highlightedText: $highlightedText
    highlightedTextStart: $highlightedTextStart
    highlightedTextEnd: $highlightedTextEnd
  ) {
    commentId
    text
    highlightedText
    highlightedTextStart
    highlightedTextEnd
    user {
      username
      familyName
      givenName
      email
    }
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      transcriptPieceId: // value for 'transcriptPieceId'
 *      text: // value for 'text'
 *      highlightedText: // value for 'highlightedText'
 *      highlightedTextStart: // value for 'highlightedTextStart'
 *      highlightedTextEnd: // value for 'highlightedTextEnd'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const SearchConversationsDocument = gql`
    mutation SearchConversations($query: String!) {
  SearchConversations(query: $query) {
    conversationId
    name
    highlightedText
    sentimentOverall
    sentimentCustomer
    sentimentAgentEmployee
  }
}
    `;
export type SearchConversationsMutationFn = Apollo.MutationFunction<SearchConversationsMutation, SearchConversationsMutationVariables>;

/**
 * __useSearchConversationsMutation__
 *
 * To run a mutation, you first call `useSearchConversationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchConversationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchConversationsMutation, { data, loading, error }] = useSearchConversationsMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchConversationsMutation(baseOptions?: Apollo.MutationHookOptions<SearchConversationsMutation, SearchConversationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchConversationsMutation, SearchConversationsMutationVariables>(SearchConversationsDocument, options);
      }
export type SearchConversationsMutationHookResult = ReturnType<typeof useSearchConversationsMutation>;
export type SearchConversationsMutationResult = Apollo.MutationResult<SearchConversationsMutation>;
export type SearchConversationsMutationOptions = Apollo.BaseMutationOptions<SearchConversationsMutation, SearchConversationsMutationVariables>;
export const SearchThemesDocument = gql`
    mutation SearchThemes($query: String!) {
  SearchThemes(query: $query) {
    themeId
    name
    categoryName
    conversationCount
  }
}
    `;
export type SearchThemesMutationFn = Apollo.MutationFunction<SearchThemesMutation, SearchThemesMutationVariables>;

/**
 * __useSearchThemesMutation__
 *
 * To run a mutation, you first call `useSearchThemesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSearchThemesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [searchThemesMutation, { data, loading, error }] = useSearchThemesMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchThemesMutation(baseOptions?: Apollo.MutationHookOptions<SearchThemesMutation, SearchThemesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SearchThemesMutation, SearchThemesMutationVariables>(SearchThemesDocument, options);
      }
export type SearchThemesMutationHookResult = ReturnType<typeof useSearchThemesMutation>;
export type SearchThemesMutationResult = Apollo.MutationResult<SearchThemesMutation>;
export type SearchThemesMutationOptions = Apollo.BaseMutationOptions<SearchThemesMutation, SearchThemesMutationVariables>;
export const EditConversationNameDocument = gql`
    mutation EditConversationName($conversationId: String!, $name: String!) {
  EditConversationName(conversationId: $conversationId, name: $name) {
    conversationId
    name
  }
}
    `;
export type EditConversationNameMutationFn = Apollo.MutationFunction<EditConversationNameMutation, EditConversationNameMutationVariables>;

/**
 * __useEditConversationNameMutation__
 *
 * To run a mutation, you first call `useEditConversationNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditConversationNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editConversationNameMutation, { data, loading, error }] = useEditConversationNameMutation({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEditConversationNameMutation(baseOptions?: Apollo.MutationHookOptions<EditConversationNameMutation, EditConversationNameMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditConversationNameMutation, EditConversationNameMutationVariables>(EditConversationNameDocument, options);
      }
export type EditConversationNameMutationHookResult = ReturnType<typeof useEditConversationNameMutation>;
export type EditConversationNameMutationResult = Apollo.MutationResult<EditConversationNameMutation>;
export type EditConversationNameMutationOptions = Apollo.BaseMutationOptions<EditConversationNameMutation, EditConversationNameMutationVariables>;
export const ConversationByIdDocument = gql`
    query ConversationById($conversationId: String!) {
  Conversation(conversationId: $conversationId) {
    conversationId
    name
    createdAt
    channel
    recordingS3Key
    recordingPresignedUrl
    sentimentCustomer
    sentimentAgentEmployee
    sentimentOverall
    transcriptPieces {
      transcriptPieceId
      speakerId
      speakerType
      startTime
      endTime
      sentiment
      text
      categories {
        categoryName
        text
      }
      comments {
        commentId
        text
        user {
          username
          familyName
          givenName
          email
        }
      }
    }
  }
}
    `;

/**
 * __useConversationByIdQuery__
 *
 * To run a query within a React component, call `useConversationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationByIdQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *   },
 * });
 */
export function useConversationByIdQuery(baseOptions: Apollo.QueryHookOptions<ConversationByIdQuery, ConversationByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationByIdQuery, ConversationByIdQueryVariables>(ConversationByIdDocument, options);
      }
export function useConversationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationByIdQuery, ConversationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationByIdQuery, ConversationByIdQueryVariables>(ConversationByIdDocument, options);
        }
export type ConversationByIdQueryHookResult = ReturnType<typeof useConversationByIdQuery>;
export type ConversationByIdLazyQueryHookResult = ReturnType<typeof useConversationByIdLazyQuery>;
export type ConversationByIdQueryResult = Apollo.QueryResult<ConversationByIdQuery, ConversationByIdQueryVariables>;
export const ConversationThemesDocument = gql`
    query ConversationThemes($categoryName: String!, $cursor: String) {
  ListThemes(categoryName: $categoryName, cursor: $cursor) {
    themeId
    name
    categoryName
    conversationCount
  }
}
    `;

/**
 * __useConversationThemesQuery__
 *
 * To run a query within a React component, call `useConversationThemesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationThemesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationThemesQuery({
 *   variables: {
 *      categoryName: // value for 'categoryName'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useConversationThemesQuery(baseOptions: Apollo.QueryHookOptions<ConversationThemesQuery, ConversationThemesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConversationThemesQuery, ConversationThemesQueryVariables>(ConversationThemesDocument, options);
      }
export function useConversationThemesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationThemesQuery, ConversationThemesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConversationThemesQuery, ConversationThemesQueryVariables>(ConversationThemesDocument, options);
        }
export type ConversationThemesQueryHookResult = ReturnType<typeof useConversationThemesQuery>;
export type ConversationThemesLazyQueryHookResult = ReturnType<typeof useConversationThemesLazyQuery>;
export type ConversationThemesQueryResult = Apollo.QueryResult<ConversationThemesQuery, ConversationThemesQueryVariables>;
export const ThemeByIdDocument = gql`
    query ThemeById($themeId: String!) {
  ThemeById(themeId: $themeId) {
    themeId
    name
    categoryName
    conversationCount
    conversations {
      conversationId
      name
      highlightedText
      sentimentOverall
      sentimentCustomer
      sentimentAgentEmployee
    }
  }
}
    `;

/**
 * __useThemeByIdQuery__
 *
 * To run a query within a React component, call `useThemeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useThemeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useThemeByIdQuery({
 *   variables: {
 *      themeId: // value for 'themeId'
 *   },
 * });
 */
export function useThemeByIdQuery(baseOptions: Apollo.QueryHookOptions<ThemeByIdQuery, ThemeByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ThemeByIdQuery, ThemeByIdQueryVariables>(ThemeByIdDocument, options);
      }
export function useThemeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ThemeByIdQuery, ThemeByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ThemeByIdQuery, ThemeByIdQueryVariables>(ThemeByIdDocument, options);
        }
export type ThemeByIdQueryHookResult = ReturnType<typeof useThemeByIdQuery>;
export type ThemeByIdLazyQueryHookResult = ReturnType<typeof useThemeByIdLazyQuery>;
export type ThemeByIdQueryResult = Apollo.QueryResult<ThemeByIdQuery, ThemeByIdQueryVariables>;
export const DashboardByIdDocument = gql`
    query DashboardById($dashboardId: String!) {
  Dashboard(dashboardId: $dashboardId) {
    dashboardId
    name
  }
}
    `;

/**
 * __useDashboardByIdQuery__
 *
 * To run a query within a React component, call `useDashboardByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardByIdQuery({
 *   variables: {
 *      dashboardId: // value for 'dashboardId'
 *   },
 * });
 */
export function useDashboardByIdQuery(baseOptions: Apollo.QueryHookOptions<DashboardByIdQuery, DashboardByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardByIdQuery, DashboardByIdQueryVariables>(DashboardByIdDocument, options);
      }
export function useDashboardByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardByIdQuery, DashboardByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardByIdQuery, DashboardByIdQueryVariables>(DashboardByIdDocument, options);
        }
export type DashboardByIdQueryHookResult = ReturnType<typeof useDashboardByIdQuery>;
export type DashboardByIdLazyQueryHookResult = ReturnType<typeof useDashboardByIdLazyQuery>;
export type DashboardByIdQueryResult = Apollo.QueryResult<DashboardByIdQuery, DashboardByIdQueryVariables>;
export const ListDashboardsDocument = gql`
    query ListDashboards($skip: Int, $take: Int) {
  ListDashboards(skip: $skip, take: $take) {
    dashboardId
    name
  }
}
    `;

/**
 * __useListDashboardsQuery__
 *
 * To run a query within a React component, call `useListDashboardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListDashboardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListDashboardsQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useListDashboardsQuery(baseOptions?: Apollo.QueryHookOptions<ListDashboardsQuery, ListDashboardsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListDashboardsQuery, ListDashboardsQueryVariables>(ListDashboardsDocument, options);
      }
export function useListDashboardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListDashboardsQuery, ListDashboardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListDashboardsQuery, ListDashboardsQueryVariables>(ListDashboardsDocument, options);
        }
export type ListDashboardsQueryHookResult = ReturnType<typeof useListDashboardsQuery>;
export type ListDashboardsLazyQueryHookResult = ReturnType<typeof useListDashboardsLazyQuery>;
export type ListDashboardsQueryResult = Apollo.QueryResult<ListDashboardsQuery, ListDashboardsQueryVariables>;
export const MyNotificationsDocument = gql`
    query MyNotifications {
  MyNotifications {
    user {
      username
      familyName
      givenName
    }
    notificationText
    notificationLink
  }
}
    `;

/**
 * __useMyNotificationsQuery__
 *
 * To run a query within a React component, call `useMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, options);
      }
export function useMyNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyNotificationsQuery, MyNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyNotificationsQuery, MyNotificationsQueryVariables>(MyNotificationsDocument, options);
        }
export type MyNotificationsQueryHookResult = ReturnType<typeof useMyNotificationsQuery>;
export type MyNotificationsLazyQueryHookResult = ReturnType<typeof useMyNotificationsLazyQuery>;
export type MyNotificationsQueryResult = Apollo.QueryResult<MyNotificationsQuery, MyNotificationsQueryVariables>;
export const OrgBySubdomainDocument = gql`
    query OrgBySubdomain($subdomain: String!) {
  OrganizationBySubdomain(subdomain: $subdomain) {
    organizationId
    organizationName
    subdomain
    userPoolId
    userPoolClientId
    userPoolRegion
    featureFlags
  }
}
    `;

/**
 * __useOrgBySubdomainQuery__
 *
 * To run a query within a React component, call `useOrgBySubdomainQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgBySubdomainQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgBySubdomainQuery({
 *   variables: {
 *      subdomain: // value for 'subdomain'
 *   },
 * });
 */
export function useOrgBySubdomainQuery(baseOptions: Apollo.QueryHookOptions<OrgBySubdomainQuery, OrgBySubdomainQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OrgBySubdomainQuery, OrgBySubdomainQueryVariables>(OrgBySubdomainDocument, options);
      }
export function useOrgBySubdomainLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrgBySubdomainQuery, OrgBySubdomainQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OrgBySubdomainQuery, OrgBySubdomainQueryVariables>(OrgBySubdomainDocument, options);
        }
export type OrgBySubdomainQueryHookResult = ReturnType<typeof useOrgBySubdomainQuery>;
export type OrgBySubdomainLazyQueryHookResult = ReturnType<typeof useOrgBySubdomainLazyQuery>;
export type OrgBySubdomainQueryResult = Apollo.QueryResult<OrgBySubdomainQuery, OrgBySubdomainQueryVariables>;
export const PlaylistByIdDocument = gql`
    query PlaylistById($playlistId: String!) {
  PlaylistWithAssignment(playlistId: $playlistId) {
    playlistId
    name
    description
    items {
      progress
      conversation {
        name
        conversationId
      }
    }
  }
}
    `;

/**
 * __usePlaylistByIdQuery__
 *
 * To run a query within a React component, call `usePlaylistByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlaylistByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlaylistByIdQuery({
 *   variables: {
 *      playlistId: // value for 'playlistId'
 *   },
 * });
 */
export function usePlaylistByIdQuery(baseOptions: Apollo.QueryHookOptions<PlaylistByIdQuery, PlaylistByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PlaylistByIdQuery, PlaylistByIdQueryVariables>(PlaylistByIdDocument, options);
      }
export function usePlaylistByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PlaylistByIdQuery, PlaylistByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PlaylistByIdQuery, PlaylistByIdQueryVariables>(PlaylistByIdDocument, options);
        }
export type PlaylistByIdQueryHookResult = ReturnType<typeof usePlaylistByIdQuery>;
export type PlaylistByIdLazyQueryHookResult = ReturnType<typeof usePlaylistByIdLazyQuery>;
export type PlaylistByIdQueryResult = Apollo.QueryResult<PlaylistByIdQuery, PlaylistByIdQueryVariables>;
export const MakeQaRequestDocument = gql`
    mutation MakeQaRequest($query: String!) {
  MakeQaRequest(query: $query) {
    answerText
    citations {
      conversation {
        conversationId
        name
        highlightedText
        sentimentOverall
        sentimentCustomer
        sentimentAgentEmployee
      }
      theme {
        themeId
        conversations {
          conversationId
          name
          highlightedText
          sentimentOverall
          sentimentCustomer
          sentimentAgentEmployee
        }
      }
    }
  }
}
    `;
export type MakeQaRequestMutationFn = Apollo.MutationFunction<MakeQaRequestMutation, MakeQaRequestMutationVariables>;

/**
 * __useMakeQaRequestMutation__
 *
 * To run a mutation, you first call `useMakeQaRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMakeQaRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [makeQaRequestMutation, { data, loading, error }] = useMakeQaRequestMutation({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useMakeQaRequestMutation(baseOptions?: Apollo.MutationHookOptions<MakeQaRequestMutation, MakeQaRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MakeQaRequestMutation, MakeQaRequestMutationVariables>(MakeQaRequestDocument, options);
      }
export type MakeQaRequestMutationHookResult = ReturnType<typeof useMakeQaRequestMutation>;
export type MakeQaRequestMutationResult = Apollo.MutationResult<MakeQaRequestMutation>;
export type MakeQaRequestMutationOptions = Apollo.BaseMutationOptions<MakeQaRequestMutation, MakeQaRequestMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  CurrentUser {
    familyName
    givenName
    username
    email
  }
  CurrentOrganization {
    organizationName
    featureFlags
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;