import { Box, Text, Container, Card, Title } from "@mantine/core";
import * as React from "react";
import { useCurrentUser } from "../pages/auth/auth";

const Home = () => {
  const currentUser = useCurrentUser();
  return (
    <Container>
      <Card shadow={"md"}>
        <Title>Welcome back {currentUser.user?.givenName}</Title>
      </Card>
    </Container>
  );
};

export { Home };
