import React from "react";
import {
  Card,
  Button,
  Tooltip,
  Switch,
  Stack,
  Grid,
  Flex,
  Box,
} from "@mantine/core";
import { ExpandMore, FormatLineSpacing } from "@mui/icons-material";
import { IconLineHeight } from "@tabler/icons";
import { useParams, useSearchParams } from "react-router-dom";

import {
  useConversationByIdQuery,
  useEditConversationNameMutation,
} from "../../__generated__/operations";

import { ChatContainer } from "../../molecules/conversations/chatUi";
import { AudioPlayer } from "../../molecules/media/audioPlayer";
import { PlaylistWidget } from "../../molecules/playlists/playlistWidget";
import {
  EditableHeading,
  EditableHeadingStatus,
} from "../../atoms/editableHeading";
import { EvaluationWidget } from "../../molecules/conversations/evaluationWidget";
import { CommentWidget } from "../../molecules/conversations/commentWidget";
import { CommentGroup } from "../../molecules/conversations/types";

const Conversation = () => {
  const [currentTranscriptPieceId, setCurrentTranscriptPieceId] =
    React.useState<string | undefined>();

  const audioPlayerRef = React.useRef<HTMLDivElement | null>(null);
  const [autoScroll, setAutoScroll] = React.useState(true);

  const params = useParams();
  const conversationId = params.id as string;
  const [searchParams, setSearchParams] = useSearchParams();
  const { loading, data, refetch } = useConversationByIdQuery({
    variables: { conversationId: conversationId },
  });
  const [editConversationNameMutation, editConvStatus] =
    useEditConversationNameMutation();

  const [showEvaluationWidget, setShowEvaluationWidget] = React.useState(false);

  const [convNameStatus, setConvNameStatus] =
    React.useState<EditableHeadingStatus>("VIEWING");

  const playlistId = searchParams.get("playlist");
  const [commentWidgetState, setCommentWidgetState] = React.useState({
    visible: false,
    highlightedText: "",
    transcriptPieceId: "",
    comments: null as CommentGroup | null,
  });

  const handleConversationNameSave = async (name: string) => {
    setConvNameStatus("SAVING");
    await editConversationNameMutation({
      variables: {
        conversationId: conversationId,
        name: name,
      },
    });
    await refetch({ conversationId: conversationId });
    setConvNameStatus("VIEWING");
  };

  const handleOpenCommentWidget = (
    highlightedText: string,
    transcriptPieceId: string,
    comments: CommentGroup | null
  ) => {
    setCommentWidgetState({
      visible: true,
      highlightedText,
      transcriptPieceId,
      comments,
    });
  };

  return (
    <Grid>
      <Grid.Col md={2.5}>
        {playlistId && playlistId ? (
          <PlaylistWidget
            conversationId={conversationId}
            playlistId={playlistId}
          />
        ) : undefined}
        <CommentWidget
          visible={commentWidgetState.visible}
          highlightedText={commentWidgetState.highlightedText}
          transcriptPieceId={commentWidgetState.transcriptPieceId}
          conversationId={data?.Conversation.conversationId!}
          onClose={() => {
            setCommentWidgetState((prev) => ({
              ...prev,
              visible: false,
            }));
          }}
          comments={commentWidgetState.comments}
        />
      </Grid.Col>
      <Grid.Col md={7}>
        {data?.Conversation && (
          <Stack>
            <Card shadow={"sm"}>
              <Flex justify="space-between">
                <EditableHeading
                  text={data.Conversation.name}
                  handleEdit={() => {
                    setConvNameStatus("EDITING");
                  }}
                  handleSave={(text) => {
                    handleConversationNameSave(text);
                  }}
                  status={convNameStatus}
                  ariaDescription="Conversation name"
                  editButtonAriaDescription="Edit conversation name"
                  saveButtonAriaDescription="Save conversation name"
                />
                <Button
                  size={"md"}
                  onClick={() => setShowEvaluationWidget(true)}
                >
                  Evaluate
                </Button>
              </Flex>
            </Card>

            {data.Conversation.recordingPresignedUrl && (
              <Box ref={audioPlayerRef}>
                <Card shadow="sm">
                  <AudioPlayer
                    src={data.Conversation.recordingPresignedUrl}
                    transcript={data.Conversation.transcriptPieces}
                    onTranscriptPieceFocus={(transcriptPieceId) => {
                      setCurrentTranscriptPieceId(transcriptPieceId);
                    }}
                    toolbar={
                      <Tooltip
                        label={`Autoscroll: ${autoScroll ? "ON" : "OFF"}`}
                      >
                        <Box>
                          <Switch
                            size="md"
                            checked={autoScroll}
                            onChange={(e) =>
                              setAutoScroll(e.currentTarget.checked)
                            }
                            onLabel={
                              <IconLineHeight size="1rem" stroke={2.5} />
                            }
                          />
                        </Box>
                      </Tooltip>
                    }
                  />
                </Card>
              </Box>
            )}
            <ChatContainer
              handleAddComment={handleOpenCommentWidget}
              conversationId={data.Conversation.conversationId}
              transcript={data.Conversation.transcriptPieces}
              focusedTranscriptPiece={currentTranscriptPieceId}
              autoScroll={autoScroll}
              scrollYOffset={
                (audioPlayerRef.current?.getBoundingClientRect().height || 0) *
                  -1 -
                20
              }
            />
          </Stack>
        )}
      </Grid.Col>
      <Grid.Col md={2.5}>
        {showEvaluationWidget ? <EvaluationWidget /> : undefined}
      </Grid.Col>
    </Grid>
  );
};

export { Conversation };
