import * as React from "react";
import { Card, Blockquote, Title, Group } from "@mantine/core";
import { Link } from "../../atoms/link";
import { SentimentBadge } from "../../atoms/sentimentBadge";

interface ConversatatioonSummaryProps {
  highlightedText?: string | null;
  conversationId: string;
  name: string;
  sentimentCustomer?: number | null | undefined;
}

function ConversationSummary(props: ConversatatioonSummaryProps) {
  return (
    <Card shadow={"md"} my={4}>
      <Group>
        <Link to={`/conversations/${props.conversationId}`}>
          <Title order={3}>{props.name}</Title>
        </Link>
        {props.sentimentCustomer && (<SentimentBadge sentiment={props.sentimentCustomer}/>)}
      </Group>

      {props.highlightedText && (
        <Blockquote>{props.highlightedText!}</Blockquote>
      )}
    </Card>
  );
}

export { ConversationSummary };
