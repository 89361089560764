import {
  CardContent,
  Container,
  Card,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { ItemsList } from "../../molecules/itemsList";
import {
  useListDashboardsQuery,
  Query,
  ListDashboardsQuery,
  DashboardModel,
} from "../../__generated__/operations";

const DashboardList = () => {
  const { loading, data, fetchMore } = useListDashboardsQuery();
  return (
    <Container sx={{ height: "80vh" }}>
      <Card sx={{ height: "inherit" }}>
        <CardContent sx={{ height: "100%" }}>
          <Grid container flexDirection={"column"} height="100%" spacing={1}>
            <Grid item>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <Typography variant="h4">Dashboards</Typography>
                </Grid>
                <Grid item>
                  <Button
                    component={Link}
                    to="/analyze/dashboards/new"
                    variant="contained"
                  >
                    New
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item flexGrow={1}>
              <Box height="100%">
                <ItemsList<"ListDashboards", ListDashboardsQuery>
                  queryFunction={useListDashboardsQuery}
                  resultsKey="ListDashboards"
                  idColumn="dashboardId"
                  columns={
                    [
                      {
                        field: "name",
                        headerName: "Name",
                        flex: 1,
                        renderCell: (params) => {
                          return (
                            <Link
                              to={`/analyze/dashboards/${params.row.dashboardId}`}
                            >
                              {params.row.name}
                            </Link>
                          );
                        },
                      },
                    ] as GridColDef<DashboardModel>[]
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export { DashboardList };
