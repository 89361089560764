import React, { useState } from "react";
import {
  Button,
  Group,
  Stack,
  Card,
  TextInput,
} from "@mantine/core";
import { ActionEditor } from "./actions/actionsEditor";
import {
  ConditionsEditor,
  ConditionsEditorState,
} from "./conditions/conditionsEditor";

interface RuleEditorState {
  conditions: ConditionsEditorState;
}

export const RuleEditor = () => {
  const [state, setState] = React.useState<RuleEditorState>({ conditions: {} });

  return (
    <Stack spacing="sm">
      <Group position="apart">
        <TextInput size="md" placeholder="Rule Name" />
        <Button size="md">Save</Button>
      </Group>
      <Card shadow="md">
        <ConditionsEditor
          value={state.conditions}
          onChange={(val) => {
            setState((prev) => ({
              ...prev,
              conditions: val,
            }));
          }}
        />
      </Card>
      <Card shadow="md">
        <ActionEditor />
      </Card>
    </Stack>
  );
};
