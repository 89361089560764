import * as React from "react";
import { createPortal } from "react-dom";
import { v4 as uuidv4 } from "uuid";

import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";

import {
  Box,
  Grid,
  InputAdornment,
  Popover,
  TextField,
  Rating,
  Typography,
  Card,
  CardContent,
  useTheme,
  Button,
  Container,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Stack,
  Slide,
  Collapse,
  Divider,
  Switch
} from "@mui/material";

// Icons
import Delete from "@mui/icons-material/Delete";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import AddIcon from "@mui/icons-material/Add";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AbcIcon from "@mui/icons-material/Abc";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NumbersIcon from "@mui/icons-material/Numbers";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

type FieldType = "FIELD" | "RATING" | "NUMBER" | "DROPDOWN" | "RADIO" | "TEXT";

type Option = { label: string; value: string };

interface Field {
  id: string;
  fieldType: FieldType;
  name: string;
  defaultValue?: string;
  title?: string;
  description?: string;
  options?: Option[];
}

const TRASH_ID = "TRASH";

const FormBuilder = () => {
  const [activeId, setActiveId] = React.useState<number | string | null>(null);
  const [items, setItems] = React.useState<Field[]>([]);
  const [previewMode, setPreviewMode] = React.useState(false);

  function handleDragEnd(event: DragEndEvent) {
    setActiveId(null);
    const { active, over } = event;

    if (!over) {
      return;
    }

    if (over.id === TRASH_ID) {
      setItems(items.filter((filteredItem) => filteredItem.id !== active.id));
      return;
    }

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  function handleAddField(fieldType: FieldType) {
    let newItem: Field | null = null;

    switch (fieldType) {
      case "FIELD":
        newItem = {
          id: uuidv4(),
          title: "This is a title",
          fieldType: "FIELD",
          name: "Field1",
        };
        break;
      case "DROPDOWN":
        newItem = {
          id: uuidv4(),
          title: "This is a title",
          fieldType: "DROPDOWN",
          name: "Dropdown1",
          options: [
            { label: "Option 1", value: "option-1" },
            { label: "Option 2", value: "option-2" },
          ],
        };
        break;
      case "NUMBER":
        newItem = {
          id: uuidv4(),
          title: "This is a title",
          fieldType: "NUMBER",
          name: "Number1",
        };
        break;
      case "RADIO":
        newItem = {
          id: uuidv4(),
          title: "This is a title",
          fieldType: "RADIO",
          name: "Radio",
          options: [
            { label: "Option 1", value: "option-1" },
            { label: "Option 2", value: "option-2" },
          ],
        };
        break;
      case "RATING":
        newItem = {
          id: uuidv4(),
          title: "This is a title",
          fieldType: "RATING",
          name: "Rating1",
        };
        break;
      case "TEXT":
        newItem = {
          id: uuidv4(),
          title: "This is a title",
          fieldType: "TEXT",
          name: "Text1",
        };
        break;
    }

    if (newItem) {
      setItems([newItem, ...items]);
    }
  }

  const handleFieldChange = (id: string, value: Field) => {
    const newItems = [...items];
    const itemToChange = items.findIndex((item) => item.id === id);
    newItems[itemToChange] = value;
    setItems(newItems);
  };

  const theme = useTheme();

  return (
    <Container maxWidth="xl">
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={(ev) => {
          setActiveId(ev.active.id);
        }}
      >
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          <Grid container mt={1} spacing={1} flexDirection="column">
            <Grid item>
              <Grid container justifyContent={"space-between"}>
                <Grid item>
                  <AddFieldButton
                    handleAddField={handleAddField}
                    disabled={previewMode}
                  />
                  <Tooltip
                    title={`Preview Mode: ${previewMode ? "ON" : "OFF"}`}
                  >
                    <Switch
                      value={previewMode}
                      onChange={(e, val) => setPreviewMode(val)}
                    />
                  </Tooltip>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="tertiary"
                    endIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Card>
                <CardContent>
                  {items.map((item, index) => (
                    <FieldEditWrapper
                      id={item.id}
                      key={item.id}
                      editMode={!previewMode}
                      index={index}
                      field={item}
                      onFieldChange={(value) =>
                        handleFieldChange(item.id, value)
                      }
                    >
                      <FieldDisplay field={item} editMode={!previewMode} />
                    </FieldEditWrapper>
                  ))}
                  {items.length === 0 && (
                    <Typography>No fields to show</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item>
              <Collapse in={!previewMode}>
                <Card>
                  <CardContent>
                    <Trash id={TRASH_ID} />
                  </CardContent>
                </Card>
              </Collapse>
            </Grid>
          </Grid>
        </SortableContext>
        {createPortal(
          <DragOverlay
            dropAnimation={{
              duration: 500,
              easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
            }}
          >
            <Box sx={{ pointerEvents: "none" }}>
              <Paper sx={{ p: 2 }}>
                <FieldDisplay
                  field={items.find((item) => item.id === activeId) as Field}
                  editMode={!previewMode}
                />
              </Paper>
            </Box>
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </Container>
  );
};

interface OptionManagerProps {
  options: Option[];
  onChange: (newOptions: Option[]) => void;
}

function OptionManager(props: OptionManagerProps) {
  const addOption = () => {
    props.onChange([...props.options, { label: "", value: "" }]);
  };
  const deleteOption = (index: number) => {
    const newOptions = [...props.options];
    newOptions.splice(index, 1);
    props.onChange(newOptions);
  };
  const editOption = (index: number, value: Option) => {
    const newOptions = [...props.options];
    newOptions[index] = value;
    props.onChange(newOptions);
  };
  return (
    <Grid container flexDirection={"column"} spacing={1}>
      <Grid item>
        <Stack spacing={1}>
          {props.options.map((option, index) => (
            <Grid container key={index}>
              <Grid item xs={5}>
                <TextField
                  value={option.label}
                  onChange={(e) => {
                    editOption(index, {
                      value: option.value,
                      label: e.target.value,
                    });
                  }}
                  fullWidth
                  label="Label"
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  value={option.value}
                  onChange={(e) => {
                    editOption(index, {
                      label: option.label,
                      value: e.target.value,
                    });
                  }}
                  fullWidth
                  label="Value"
                />
              </Grid>
              <Grid item xs={2} alignSelf="center">
                <Tooltip title="Delete Option">
                  <IconButton onClick={() => deleteOption(index)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
          {props.options.length < 1 && (
            <Typography>No options to show.</Typography>
          )}
        </Stack>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={addOption}>
          Add Item
        </Button>
      </Grid>
    </Grid>
  );
}

interface FieldEditWrapperProps {
  children: React.ReactNode;
  id: string;
  editMode: boolean;
  index: number;
  field: Field;
  onFieldChange: (value: Field) => void;
}

const FieldEditWrapper: React.FC<FieldEditWrapperProps> = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: props.id });

  const transformStyle = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : "none",
    transition,
  };

  const [showEditDialog, setShowEditDialog] = React.useState(false);

  return (
    <>
      <FieldEditDialog
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}
        onSave={(val) => props.onFieldChange(val)}
        field={props.field}
      />
      <Grid
        container
        ref={setNodeRef}
        sx={{
          cursor: props.editMode ? (transform ? "grabbing" : "grab") : "auto",
          opacity: isDragging ? 0.5 : 1,
          ...transformStyle,
        }}
        justifyContent="space-between"
      >
        {!props.editMode && (
          <Grid item xs={12}>
            {props.children}
          </Grid>
        )}

        {props.editMode && (
          <>
            <Grid
              item
              {...listeners}
              {...attributes}
              flexGrow={1}
              maxWidth="95%"
            >
              {props.children}
            </Grid>
            <Grid item alignSelf={"center"}>
              <Tooltip title="Edit Field">
                <IconButton onClick={() => setShowEditDialog(true)}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};

interface FieldEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSave: (val: Field) => void;
  field: Field;
}

function FieldEditDialog(props: FieldEditDialogProps) {
  const [field, setField] = React.useState(props.field);

  const handleFieldChange = (key: keyof Field, value: string) => {
    const newField: Field = { ...field };
    if (key === "fieldType") {
      console.error("Tried to edit fieldType!");
      return;
    } else if (key === "options") {
      console.error("Tried to edit options in handleFieldChange!");
      return;
    } else {
      newField[key] = value;
    }

    setField(newField);
  };

  const handleOptionsChange = (options: Option[]) => {
    setField({ ...field, options: options });
  };
  return (
    <Dialog
      sx={{ "& .MuiDialog-paper": { minWidth: "60vw" } }}
      open={props.open}
      onClose={props.onClose}
    >
      <DialogTitle>Edit Field</DialogTitle>
      <DialogContent>
        <Grid container flexDirection={"column"} sx={{ pt: 1 }} spacing={1}>
          <Grid item>
            <TextField
              value={field.title}
              fullWidth
              title="Title"
              label="Title"
              onChange={(e) => handleFieldChange("title", e.target.value)}
            />
          </Grid>
          <Grid item>
            <TextField
              value={field.name}
              fullWidth
              onChange={(e) => handleFieldChange("name", e.target.value)}
              title="Field Name"
              label="Field Name"
            />
          </Grid>
          <Grid item>
            <TextField
              value={field.description || ""}
              multiline
              minRows={3}
              fullWidth
              onChange={(e) => handleFieldChange("description", e.target.value)}
              title="Description"
              label="Description"
            />
          </Grid>
          {["DROPDOWN", "RADIO"].includes(field.fieldType) && (
            <Grid item>
              <Divider />
              <Typography variant="h4">Available Values</Typography>
              <Box my={1}>
                <OptionManager
                  options={field.options || []}
                  onChange={handleOptionsChange}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="tertiary" onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            props.onSave(field);
            props.onClose();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function Trash({ id }: { id: string }) {
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });
  const theme = useTheme();
  return (
    <Box
      ref={setNodeRef}
      width={"100%"}
      sx={{
        border: `2px dashed ${
          isOver ? theme.palette.grey[500] : theme.palette.grey[200]
        }`,
      }}
    >
      <Grid container justifyContent={"center"}>
        <Grid item>
          <Delete fontSize="large" />
        </Grid>
      </Grid>
    </Box>
  );
}

interface FieldDisplayProps {
  field: Field;
  editMode: boolean;
}

const FieldDisplay = React.memo(function FieldDisplay(
  props: FieldDisplayProps
) {
  const FieldsMap = () => {
    switch (props.field.fieldType) {
      case "RATING":
        return <Rating />;
      case "DROPDOWN":
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={10}
          >
            {props.field.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        );
      case "FIELD":
        return <TextField fullWidth></TextField>;
      case "NUMBER":
        return (
          <TextField
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <NumbersIcon />
                </InputAdornment>
              ),
            }}
          />
        );
      case "RADIO":
        return (
          <RadioGroup>
            {props.field.options?.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={<Radio />}
                label={option.label}
              />
            ))}
            {!props.field.options && (
              <Typography>No options to show.</Typography>
            )}
          </RadioGroup>
        );
      case "TEXT":
        return (
          <>
            {!props.field.title && !props.field.description && (
              <Typography>Placeholder text.</Typography>
            )}
          </>
        );
    }
  };

  return (
    <Box sx={{ pointerEvents: props.editMode ? "none" : "auto" }} my={1}>
      {props.field.title && (
        <Typography fontWeight={"bold"} component="legend">
          {props.field.title}
        </Typography>
      )}
      {props.field.description && (
        <Typography component="legend">{props.field.description}</Typography>
      )}
      <FieldsMap />
    </Box>
  );
});

interface AddFieldButtonProps {
  handleAddField: (fieldType: FieldType) => void;
  disabled?: boolean;
}

const AddFieldButton = (props: AddFieldButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const ICONS = [
    [
      {
        icon: <TextFieldsIcon sx={{ fontSize: "64px", textAlign: "center" }} />,
        label: "Text Input",
        onClick: () => {
          props.handleAddField("FIELD");
          handleClose();
        },
      },
      {
        icon: <StarHalfIcon sx={{ fontSize: "64px", textAlign: "center" }} />,
        label: "Rating",
        onClick: () => {
          props.handleAddField("RATING");
          handleClose();
        },
      },
      {
        icon: (
          <FormatListBulletedIcon
            sx={{ fontSize: "64px", textAlign: "center" }}
          />
        ),
        label: "Radio",
        onClick: () => {
          props.handleAddField("RADIO");
          handleClose();
        },
      },
    ],
    [
      {
        icon: <AbcIcon sx={{ fontSize: "64px", textAlign: "center" }} />,
        label: "Text",
        onClick: () => {
          props.handleAddField("TEXT");
          handleClose();
        },
      },
      {
        icon: (
          <ArrowDropDownIcon sx={{ fontSize: "64px", textAlign: "center" }} />
        ),
        label: "Dropdown",
        onClick: () => {
          props.handleAddField("DROPDOWN");
          handleClose();
        },
      },
      {
        icon: <NumbersIcon sx={{ fontSize: "64px", textAlign: "center" }} />,
        label: "Number",
        onClick: () => {
          props.handleAddField("NUMBER");
          handleClose();
        },
      },
    ],
  ];

  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        endIcon={<AddIcon />}
        disabled={props.disabled}
      >
        Add Field
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {ICONS.map((iconRow) => (
          <Grid container>
            {iconRow.map((icon) => (
              <Grid
                item
                onClick={icon.onClick}
                sx={{
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  },
                }}
              >
                <Box m={2} width="80px">
                  <Grid container flexDirection={"column"} alignItems="center">
                    <Grid item>{icon.icon}</Grid>
                    <Grid item>
                      <Typography textAlign={"center"} fontSize={"16px"}>
                        {icon.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            ))}
          </Grid>
        ))}
      </Popover>
    </>
  );
};

export { FormBuilder };
